import React, { useState } from 'react';
import {
  useGetLotteryInfoQuery,
  useGetUserTicketsQuery,
  useGetDrawHistoryQuery,
  useBuyTicketMutation,
} from '../../api/properties';
import './lottery.css';

const Lottery = () => {
  const [activeTab, setActiveTab] = useState('potInfo');
  const [selectedNumbers, setSelectedNumbers] = useState([]);

  const { data: lotteryInfo, isLoading: infoLoading } =
    useGetLotteryInfoQuery();
  const { data: userTickets, isLoading: ticketsLoading } =
    useGetUserTicketsQuery();
  const { data: drawHistory, isLoading: historyLoading } =
    useGetDrawHistoryQuery();
  const [buyTicket] = useBuyTicketMutation();

  const handleTabClick = tabName => {
    setActiveTab(tabName);
  };

  const handleNumberSelection = number => {
    if (selectedNumbers.includes(number)) {
      setSelectedNumbers(selectedNumbers.filter(num => num !== number));
    } else if (selectedNumbers.length < 3) {
      setSelectedNumbers([...selectedNumbers, number].sort((a, b) => a - b));
    } else {
      alert('You can only select up to 3 numbers.');
    }
  };

  const luckyDip = () => {
    const numbers = [];
    while (numbers.length < 3) {
      const randomNumber = Math.floor(Math.random() * 30) + 1;
      if (!numbers.includes(randomNumber)) {
        numbers.push(randomNumber);
      }
    }
    setSelectedNumbers(numbers.sort((a, b) => a - b));
  };

  const handleBuyTicket = async () => {
    if (selectedNumbers.length !== 3) {
      alert('Please select 3 numbers before buying a ticket.');
      return;
    }
    try {
      await buyTicket({ numbers: selectedNumbers.join(', ') }).unwrap();
      alert('Ticket purchased successfully!');
      setSelectedNumbers([]);
    } catch (error) {
      alert('Failed to buy ticket. Please try again.');
    }
  };

  return (
    <div className="row">
      <div className="col-lg-2"></div>
      <div className="col-lg-8">
        <div className="lottery-tabs-container">
          <div className="tabs-wrapper">
            <ul className="nav nav-tabs flex-nowrap">
              <li className="nav-item">
                <a
                  href="#pot-info"
                  onClick={() => handleTabClick('potInfo')}
                  className={`nav-link ${activeTab === 'potInfo' ? 'active' : ''}`}
                >
                  Pot Information
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="#buy-ticket"
                  onClick={() => handleTabClick('buyTicket')}
                  className={`nav-link ${activeTab === 'buyTicket' ? 'active' : ''}`}
                >
                  Buy a ticket!
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="#your-tickets"
                  onClick={() => handleTabClick('yourTickets')}
                  className={`nav-link ${activeTab === 'yourTickets' ? 'active' : ''}`}
                >
                  Your Tickets
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="#draw-history"
                  onClick={() => handleTabClick('drawHistory')}
                  className={`nav-link ${activeTab === 'drawHistory' ? 'active' : ''}`}
                >
                  Draw History and Data
                </a>
              </li>
            </ul>
          </div>

          <div className="tab-content panel rounded-0 p-3 m-0">
            <div
              className={`tab-pane fade ${activeTab === 'potInfo' ? 'active show' : ''}`}
              id="pot-info"
            >
              <h2>Pot Information</h2>
              {infoLoading ? (
                <p>Loading...</p>
              ) : (
                <>
                  <p>Estimated Jackpot: {lotteryInfo?.jackpot}</p>
                  <p>Total tickets bought: {lotteryInfo?.totalTickets}</p>
                  <p>Next Draw: {lotteryInfo?.nextDraw}</p>
                </>
              )}
            </div>

            <div
              className={`tab-pane fade ${activeTab === 'buyTicket' ? 'active show' : ''}`}
              id="buy-ticket"
            >
              <h2>Buy a ticket</h2>
              <div className="number-selector">
                {[...Array(3)].map((_, row) => (
                  <div key={row} className="number-row">
                    {[...Array(10)].map((_, col) => {
                      const number = row * 10 + col + 1;
                      return number <= 30 ? (
                        <div
                          key={number}
                          className={`number-circle ${selectedNumbers.includes(number) ? 'selected' : ''}`}
                          onClick={() => handleNumberSelection(number)}
                        >
                          {number}
                        </div>
                      ) : null;
                    })}
                  </div>
                ))}
              </div>
              <div className="buttons-container">
                <button onClick={luckyDip} className="lucky-dip-button">
                  Lucky Dip
                </button>
                <button
                  onClick={handleBuyTicket}
                  disabled={selectedNumbers.length !== 3}
                  className="submit-button"
                >
                  Buy Ticket
                </button>
              </div>
              <div className="selected-numbers-container">
                <div className="selected-numbers-list">
                  {selectedNumbers.map(number => (
                    <div key={number} className="selected-number-circle">
                      {number}
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div
              className={`tab-pane fade ${activeTab === 'yourTickets' ? 'active show' : ''}`}
              id="your-tickets"
            >
              <h2>Your Tickets</h2>
              {ticketsLoading ? (
                <p>Loading your tickets...</p>
              ) : (
                <table className="table">
                  <thead>
                    <tr>
                      <th>Ticket ID</th>
                      <th>Numbers</th>
                      <th>Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userTickets?.map(ticket => (
                      <tr key={ticket.id}>
                        <td>{ticket.id}</td>
                        <td>{ticket.numbers}</td>
                        <td>{new Date(ticket.time).toLocaleString()}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>

            <div
              className={`tab-pane fade ${activeTab === 'drawHistory' ? 'active show' : ''}`}
              id="draw-history"
            >
              <h2>Draw History and Data</h2>
              {historyLoading ? (
                <p>Loading draw history...</p>
              ) : (
                <table className="table">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Numbers</th>
                      <th>Winner</th>
                    </tr>
                  </thead>
                  <tbody>
                    {drawHistory?.map(draw => (
                      <tr key={draw.id}>
                        <td>{new Date(draw.date).toLocaleDateString()}</td>
                        <td>{draw.numbers}</td>
                        <td>{draw.winner}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-2"></div>
    </div>
  );
};

export default Lottery;
