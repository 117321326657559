import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  useGetForumTopicsQuery,
  useCreateForumTopicMutation,
  useGetForumTopicQuery,
  useCreateForumCommentMutation,
} from '../../api/comm';
import Response from '../../shared-components/response';

const ForumList = ({ topics, onTopicClick }) => (
  <div className="list-group">
    {topics.map(topic => (
      <button
        key={topic.uuid}
        className="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
        onClick={() => onTopicClick(topic.uuid)}
      >
        <div>
          <h5 className="mb-1">{topic.title}</h5>
          <small>By {topic.author.username}</small>
        </div>
        <small>{new Date(topic.createdAt).toLocaleString()}</small>
      </button>
    ))}
  </div>
);

const CreateTopicModal = ({ onClose, forumType }) => {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [createTopic] = useCreateForumTopicMutation();
  const [alertData, setAlertData] = useState({ message: '', type: '' });

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      await createTopic({ title, content, forumType }).unwrap();
      setAlertData({ message: 'Topic created successfully!', type: 'success' });
      setTimeout(onClose, 1500);
    } catch (error) {
      setAlertData({
        message: 'Failed to create topic. Please try again.',
        type: 'error',
      });
    }
  };

  return (
    <div
      className="modal"
      style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Create New Topic</h5>
            <button type="button" className="close" onClick={onClose}>
              <span>&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Response message={alertData.message} type={alertData.type} />
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="title">Title</label>
                <input
                  type="text"
                  className="form-control"
                  id="title"
                  value={title}
                  onChange={e => setTitle(e.target.value)}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="content">Content</label>
                <textarea
                  className="form-control"
                  id="content"
                  rows="3"
                  value={content}
                  onChange={e => setContent(e.target.value)}
                  required
                ></textarea>
              </div>
              <button type="submit" className="btn btn-primary">
                Create Topic
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

const TopicView = ({ topicId }) => {
  const { data: topic, isLoading } = useGetForumTopicQuery(topicId);
  const [comment, setComment] = useState('');
  const [createComment] = useCreateForumCommentMutation();

  const handleCommentSubmit = async e => {
    e.preventDefault();
    try {
      await createComment({ topicId, content: comment }).unwrap();
      setComment('');
    } catch (error) {
      console.error('Failed to post comment:', error);
    }
  };

  if (isLoading) return <p>Loading topic...</p>;

  return (
    <div>
      <p>{topic.content}</p>
      <small>By {topic.author.username}</small>
      <hr />
      <h4>Comments</h4>
      {topic.comments.map(comment => (
        <div key={comment.uuid} className="card mb-2">
          <div className="card-body">
            <p>{comment.content}</p>
            <small>By {comment.author.username}</small>
          </div>
        </div>
      ))}
      <form onSubmit={handleCommentSubmit}>
        <div className="form-group">
          <label htmlFor="comment">Add a comment</label>
          <textarea
            className="form-control"
            id="comment"
            rows="2"
            value={comment}
            onChange={e => setComment(e.target.value)}
            required
          ></textarea>
        </div>
        <button type="submit" className="btn btn-primary">
          Post Comment
        </button>
      </form>
    </div>
  );
};

const Forums = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [forumType, setForumType] = useState('main');
  const { data: topics, isLoading } = useGetForumTopicsQuery(forumType);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [selectedTopicId, setSelectedTopicId] = useState(null);

  const { data: currentTopic } = useGetForumTopicQuery(selectedTopicId, {
    skip: !selectedTopicId,
  });

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const forum = params.get('forum');
    if (forum === 'main' || forum === 'family') {
      setForumType(forum);
    } else {
      navigate('?forum=main');
    }
  }, [location, navigate]);

  return (
    <div className="container mt-4">
      <div className="panel panel-inverse">
        <div className="panel-heading">
          <h4 className="panel-title text-center">
            {selectedTopicId ? currentTopic?.title : 'Forums'}
          </h4>
          <div className="panel-heading-btn">
            {selectedTopicId ? (
              <button
                className="btn btn-secondary"
                onClick={() => setSelectedTopicId(null)}
              >
                Back to Topics
              </button>
            ) : (
              <button
                className="btn btn-primary"
                onClick={() => setShowCreateModal(true)}
              >
                Create New Topic
              </button>
            )}
          </div>
        </div>
        <div className="panel-body p-0">
          <div className="table-responsive">
            {isLoading ? (
              <p>Loading topics...</p>
            ) : selectedTopicId ? (
              <div className="panel panel-inverse">
                <div className="panel-body">
                  <TopicView topicId={selectedTopicId} />
                </div>
              </div>
            ) : (
              <ForumList
                topics={topics}
                onTopicClick={topicId => setSelectedTopicId(topicId)}
              />
            )}
          </div>
        </div>
      </div>
      {showCreateModal && (
        <CreateTopicModal
          onClose={() => setShowCreateModal(false)}
          forumType={forumType}
        />
      )}
    </div>
  );
};

export default Forums;
