import { useLocation, Navigate, Outlet } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectCurrentToken,
  setCredentials,
  selectInPrison,
} from './authSlice';
import { useEffect, useState, useRef } from 'react';
import { useGlobalState } from './globalState';
import { useGetTimersQuery } from '../api/auth';
import YouAreInPrison from '../components/YouAreInPrison';

const RequireAuth = () => {
  const token = useSelector(selectCurrentToken);
  const inPrison = useSelector(selectInPrison);
  const location = useLocation();
  const dispatch = useDispatch();
  const { globalState, updateGlobalState, updateAllTimers } = useGlobalState();
  const [isChecking, setIsChecking] = useState(true);
  const [shouldFetchTimers, setShouldFetchTimers] = useState(false);
  const prevCharacterUuid = useRef(null);

  const prisonCheckPaths = [
    '/game/crime/crimes',
    '/game/crime/adv-crime',
    '/game/crime/gta',
    '/game/crime/bta',
    '/game/crime/chase',
    '/game/crime/extortion',
    '/game/crime/missions',
    '/game/crime/organised-crime',
    '/game/crime/drugs',
    '/game/crime/prison',
    '/game/crime/encounters',
    '/game/crime/syndicates',
    '/game/kill/kill',
    '/game/kill/bullet-factory',
    '/game/kill/kevlar-factory',
    '/game/kill/foundry',
    '/game/kill/russian-roulette',
    '/game/casino/roulette',
    '/game/casino/dice',
    '/game/casino/slots',
    '/game/casino/heads-or-tails',
    '/game/casino/boxing',
    '/game/casino/lottery',
    '/game/casino/keno',
    '/game/casino/scratchcards',
    '/game/general/travel',
  ];

  const shouldCheckPrison = prisonCheckPaths.some(path =>
    location.pathname.startsWith(path)
  );

  const { data: timers } = useGetTimersQuery(undefined, {
    skip: !token || !shouldFetchTimers,
  });

  useEffect(() => {
    const fetchDataAndSetState = async () => {
      if (!token) {
        const storedToken = localStorage.getItem('token');
        const storedUser = JSON.parse(localStorage.getItem('user'));
        const storedCharacter = JSON.parse(localStorage.getItem('character'));

        if (storedToken && storedUser) {
          dispatch(
            setCredentials({ user: storedUser, access_token: storedToken })
          );
          updateGlobalState({
            uuid: storedUser.uuid,
            role: storedUser.role,
            email: storedUser.email,
            character_uuid: storedCharacter?.uuid,
            character_name: storedCharacter?.username,
            data: {
              displayName: storedCharacter?.username,
              photoURL: storedCharacter?.avatar || '',
            },
          });
        }
      }
      setIsChecking(false);
    };

    fetchDataAndSetState();
  }, [token, dispatch, updateGlobalState]);

  useEffect(() => {
    if (globalState.character_uuid !== prevCharacterUuid.current) {
      setShouldFetchTimers(true);
      prevCharacterUuid.current = globalState.character_uuid;
    }
  }, [globalState.character_uuid]);

  useEffect(() => {
    if (timers && shouldFetchTimers) {
      updateAllTimers(timers);
      setShouldFetchTimers(false);
    }
  }, [timers, updateAllTimers, shouldFetchTimers, globalState]);

  if (isChecking) {
    return null;
  }

  if (shouldCheckPrison && inPrison) {
    return <YouAreInPrison />;
  }

  return token ? (
    <Outlet />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default RequireAuth;
