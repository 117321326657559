import { apiSlice } from '../auth/apiSlice';

export const adminApi = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getUsers: builder.query({
            query: (params) => ({
              url: 'admin/users',
              method: 'GET',
              params: {
                search: params?.search,
                page: params?.page,
                limit: params?.limit,
                sortBy: params?.sortBy,
                sortOrder: params?.sortOrder
              }
            })
          }),         
          getAdminCharacters: builder.query({
            query: (params) => ({
              url: 'admin/characters',
              method: 'GET',
              params: {
                search: params?.search,
                page: params?.page,
                limit: params?.limit,
                sortBy: params?.sortBy,
                sortOrder: params?.sortOrder
              }
            })
          }),
          getAdminCharacterDetails: builder.query({
            query: (uuid) => ({
              url: `admin/characters/${uuid}`,
              method: 'GET'
            })
          })
          










    })
})

export const {
    useGetUsersQuery,
    useGetAdminCharactersQuery,
    useGetAdminCharacterDetailsQuery
} = adminApi;