import { apiSlice } from '../auth/apiSlice';

export const commApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getMessengerChat: builder.query({
      query: () => '/comms/gamechat',
      providesTags: ['messenger_chat'],
    }),
    sendMessengerMessage: builder.mutation({
      query: body => ({
        url: '/comms/gamechat',
        method: 'POST',
        body: { ...body },
      }),
      invalidatesTags: ['messenger_chat'],
    }),
    getIdeas: builder.query({
      query: status => `/comms/ideas?status=${status}`,
      providesTags: ['ideas'],
    }),
    submitIdea: builder.mutation({
      query: body => ({
        url: '/comms/ideas',
        method: 'POST',
        body: { ...body },
      }),
      invalidatesTags: ['ideas'],
    }),
    voteIdea: builder.mutation({
      query: ({ ideaId, vote }) => ({
        url: `/comms/ideas/${ideaId}/vote`,
        method: 'POST',
        body: { vote },
      }),
      invalidatesTags: ['ideas'],
    }),
    commentIdea: builder.mutation({
      query: ({ ideaId, content }) => ({
        url: `/comms/ideas/${ideaId}/comment`,
        method: 'POST',
        body: { content },
      }),
      invalidatesTags: ['ideas'],
    }),
    getIdeaComments: builder.query({
      query: ideaId => `/comms/ideas/${ideaId}/comments`,
      providesTags: ['comments'],
    }),
    ///forum
    getForumTopics: builder.query({
      query: forumType => `/comms/forums/${forumType}/topics`,
      providesTags: ['forumTopics'],
    }),
    createForumTopic: builder.mutation({
      query: body => ({
        url: '/comms/forums/topics',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['forumTopics'],
    }),
    getForumTopic: builder.query({
      query: topicId => `/comms/forums/topics/${topicId}`,
      providesTags: ['forumTopic'],
    }),
    createForumComment: builder.mutation({
      query: body => ({
        url: '/comms/forums/comments',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['forumTopic'],
    }),
    getInbox: builder.query({
      query: () => '/comms/inbox',
      providesTags: ['inbox'],
    }),
    getConversation: builder.query({
      query: subject => `/comms/conversation/${subject}`,
      providesTags: ['conversation'],
    }),
    sendMessage: builder.mutation({
      query: body => ({
        url: '/comms/send-message',
        method: 'POST',
        body: { ...body },
      }),
      invalidatesTags: ['inbox', 'conversation'],
    }),
  }),
});

export const {
  useGetMessengerChatQuery,
  useSendMessengerMessageMutation,
  useGetIdeasQuery,
  useSubmitIdeaMutation,
  useVoteIdeaMutation,
  useCommentIdeaMutation,
  useGetIdeaCommentsQuery,
  useGetForumTopicsQuery,
  useCreateForumTopicMutation,
  useGetForumTopicQuery,
  useCreateForumCommentMutation,
  useGetInboxQuery,
  useGetConversationQuery,
  useSendMessageMutation,
} = commApi;
