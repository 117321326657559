import React, { useState } from 'react';
import {
  useGetInboxQuery,
  useGetConversationQuery,
  useSendMessageMutation,
} from '../../api/comm';
import Response from '../../shared-components/response';
import { useGlobalState } from '../../auth/globalState';

const ConversationModal = ({ subject, onClose }) => {
  const [message, setMessage] = useState('');
  const [sendMessage] = useSendMessageMutation();
  const {
    data: messages,
    isLoading,
    refetch,
  } = useGetConversationQuery(subject);
  const [alertData, setAlertData] = useState({ message: '', type: '' });

  const handleMessageSubmit = async () => {
    if (!message.trim()) {
      setAlertData({ message: 'Message cannot be empty', type: 'error' });
      return;
    }

    try {
      await sendMessage({ subject, content: message.trim(), isReply: true });
      setMessage('');
      refetch();
      setAlertData({ message: 'Message sent successfully!', type: 'success' });
    } catch (error) {
      setAlertData({
        message: 'Failed to send message. Please try again.',
        type: 'error',
      });
    }
  };

  return (
    <div
      className="modal"
      style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }}
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">{subject}</h4>
            <button type="button" className="close" onClick={onClose}>
              <span>&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Response message={alertData.message} type={alertData.type} />
            {isLoading ? (
              <p>Loading messages...</p>
            ) : (
              messages &&
              messages.map(msg => (
                <div key={msg.uuid} className="card mt-2">
                  <div className="card-body">
                    <p>{msg.content}</p>
                    <small>
                      From: {msg.sender.username} To: {msg.receiver.username}
                    </small>
                  </div>
                </div>
              ))
            )}
            <div className="mt-4">
              <textarea
                className="form-control"
                value={message}
                onChange={e => setMessage(e.target.value)}
                placeholder="Write a message..."
              />
              <button
                className="btn btn-primary mt-2"
                onClick={handleMessageSubmit}
              >
                Send Message
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ConversationList = ({ onConversationClick }) => {
  const { data: conversations, isLoading } = useGetInboxQuery();
  const { globalState } = useGlobalState();
  const currentCharacterUuid = globalState.character_uuid;

  if (isLoading) return <p>Loading...</p>;

  return (
    <table className="table">
      <thead>
        <tr>
          <th>Username</th>
          <th>Subject</th>
          <th>Read</th>
        </tr>
      </thead>
      <tbody>
        {conversations &&
          conversations.map(conv => {
            const otherUsername =
              conv.senderUuid === currentCharacterUuid
                ? conv.receiverUsername
                : conv.senderUsername;
            return (
              <tr
                key={conv.subject}
                onClick={() => onConversationClick(conv.subject)}
                style={{ cursor: 'pointer' }}
              >
                <td>{otherUsername}</td>
                <td>{conv.subject}</td>
                <td>{conv.unreadCount > 0 ? 'Unread' : 'Read'}</td>
              </tr>
            );
          })}
      </tbody>
    </table>
  );
};

const NewConversation = ({ setAlertData }) => {
  const [username, setUsername] = useState('');
  const [subject, setSubject] = useState('');
  const [content, setContent] = useState('');
  const [sendMessage] = useSendMessageMutation();

  const handleSubmit = async e => {
    e.preventDefault();
    if (!username.trim() || !subject.trim() || !content.trim()) {
      setAlertData({ message: 'All fields are required', type: 'error' });
      return;
    }

    try {
      await sendMessage({
        receiverUsername: username.trim(),
        subject: subject.trim(),
        content: content.trim(),
      });
      setAlertData({
        message: 'New conversation started successfully!',
        type: 'success',
      });
      setUsername('');
      setSubject('');
      setContent('');
    } catch (error) {
      setAlertData({
        message:
          error.data?.error ||
          'Failed to start new conversation. Please try again.',
        type: 'error',
      });
    }
  };

  return (
    <div className="card">
      <div className="card-body">
        <h3 className="card-title">Start New Conversation</h3>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="username">To Username:</label>
            <input
              type="text"
              className="form-control"
              id="username"
              value={username}
              onChange={e => setUsername(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="subject">Subject:</label>
            <input
              type="text"
              className="form-control"
              id="subject"
              value={subject}
              onChange={e => setSubject(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="content">Message:</label>
            <textarea
              className="form-control"
              id="content"
              value={content}
              onChange={e => setContent(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="btn btn-primary">
            Start Conversation
          </button>
        </form>
      </div>
    </div>
  );
};

const Inbox = () => {
  const [alertData, setAlertData] = useState({ message: '', type: '' });
  const [selectedConversation, setSelectedConversation] = useState(null);

  const handleConversationClick = subject => {
    setSelectedConversation(subject);
  };

  const handleBackToInbox = () => {
    setSelectedConversation(null);
  };

  return (
    <div>
      {!selectedConversation ? (
        <>
          <div className="panel rounded-0 p-3 m-0">
            <ConversationList onConversationClick={handleConversationClick} />
          </div>
          <div className="mt-4">
            <Response message={alertData.message} type={alertData.type} />
            <NewConversation setAlertData={setAlertData} />
          </div>
        </>
      ) : (
        <ConversationView
          subject={selectedConversation}
          onBack={handleBackToInbox}
          setAlertData={setAlertData}
        />
      )}
    </div>
  );
};

const ConversationView = ({ subject, onBack, setAlertData }) => {
  const [message, setMessage] = useState('');
  const [sendMessage] = useSendMessageMutation();
  const {
    data: messages,
    isLoading,
    refetch,
  } = useGetConversationQuery(subject);

  const handleMessageSubmit = async () => {
    try {
      await sendMessage({ subject, content: message, isReply: true });
      setMessage('');
      refetch();
      setAlertData({ message: 'Message sent successfully!', type: 'success' });
    } catch (error) {
      setAlertData({
        message: 'Failed to send message. Please try again.',
        type: 'error',
      });
    }
  };

  return (
    <div>
      <button className="btn btn-secondary mb-3" onClick={onBack}>
        Back to Inbox
      </button>
      <h2>{subject}</h2>
      {isLoading ? (
        <p>Loading messages...</p>
      ) : (
        messages &&
        messages.map(msg => (
          <div key={msg.uuid} className="card mt-2">
            <div className="card-body">
              <p>{msg.content}</p>
              <small>
                From: {msg.sender.username} To: {msg.receiver.username}
              </small>
            </div>
          </div>
        ))
      )}
      <div className="mt-4">
        <textarea
          className="form-control"
          value={message}
          onChange={e => setMessage(e.target.value)}
          placeholder="Write a message..."
        />
        <button className="btn btn-primary mt-2" onClick={handleMessageSubmit}>
          Send Message
        </button>
      </div>
    </div>
  );
};

export default Inbox;
