import { apiSlice } from '../auth/apiSlice';

export const killApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    playRussianRoulette: builder.mutation({
      query: body => ({
        url: '/kill/russianroulette',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['roulette_info'],
    }),
    getRussianRouletteInfo: builder.query({
      query: () => '/kill/russianroulette/info',
      providesTags: ['roulette_info'],
    }),
    listSearches: builder.query({
      query: () => '/kill/searches',
      providesTags: ['Searches'],
    }),
    startSearch: builder.mutation({
      query: body => ({
        url: '/kill/search',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Searches'],
    }),
    listAttempts: builder.query({
      query: () => '/kill/attempts',
      providesTags: ['Attempts'],
    }),
    listStatements: builder.query({
      query: () => '/kill/statements',
      providesTags: ['Statements'],
    }),
    getHitlist: builder.query({
      query: () => '/kill/hitlist',
      providesTags: ['Hitlist'],
    }),
    addToHitlist: builder.mutation({
      query: body => ({
        url: '/kill/hitlist',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Hitlist'],
    }),
  }),
});

export const {
  usePlayRussianRouletteMutation,
  useGetRussianRouletteInfoQuery,
  useListSearchesQuery,
  useStartSearchMutation,
  useListAttemptsQuery,
  useListStatementsQuery,
  useGetHitlistQuery,
  useAddToHitlistMutation,
} = killApi;
