import { apiSlice } from '../auth/apiSlice';

export const marketplaceApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getItemListings: builder.query({
      query: () => '/market/items',
    }),
    addItemListing: builder.mutation({
      query: body => ({
        url: '/market/items',
        method: 'POST',
        body,
      }),
    }),
    getPropertyListings: builder.query({
      query: () => '/market/properties',
    }),
    addPropertyListing: builder.mutation({
      query: body => ({
        url: '/market/properties',
        method: 'POST',
        body,
      }),
    }),
    getInsuranceListings: builder.query({
      query: () => '/market/insurance',
    }),
    addInsuranceListing: builder.mutation({
      query: body => ({
        url: '/market/insurance',
        method: 'POST',
        body,
      }),
    }),
    getPointListings: builder.query({
      query: () => '/market/points',
    }),
    addPointListing: builder.mutation({
      query: body => ({
        url: '/market/points',
        method: 'POST',
        body,
      }),
    }),
    getLockpickListings: builder.query({
      query: () => '/market/lockpicks',
    }),
    addLockpickListing: builder.mutation({
      query: body => ({
        url: '/market/lockpicks',
        method: 'POST',
        body,
      }),
    }),
    buyItem: builder.mutation({
      query: id => ({
        url: `/market/buy/${id}`,
        method: 'POST',
      }),
    }),
    getBarItems: builder.query({
      query: () => '/market/bar-items',
    }),

    sendBarItem: builder.mutation({
      query: body => ({
        url: '/market/send-bar-item',
        method: 'POST',
        body,
      }),
    }),

    getGarageVehicles: builder.query({
      query: () => '/market/garage',
    }),
    shipVehicle: builder.mutation({
      query: ({ uuid, destination }) => ({
        url: '/market/garage/ship',
        method: 'POST',
        body: { uuid, destination },
      }),
    }),
    sendVehicle: builder.mutation({
      query: ({ uuid, recipient }) => ({
        url: '/market/garage/send',
        method: 'POST',
        body: { uuid, recipient },
      }),
    }),
    repairVehicle: builder.mutation({
      query: ({ uuid }) => ({
        url: '/market/garage/repair',
        method: 'POST',
        body: { uuid },
      }),
    }),
    scrapVehicle: builder.mutation({
      query: ({ uuid }) => ({
        url: '/market/garage/scrap',
        method: 'POST',
        body: { uuid },
      }),
    }),

    getMarineVehicles: builder.query({
      query: () => '/market/marina',
    }),
    shipMarineVehicle: builder.mutation({
      query: ({ uuid, destination }) => ({
        url: '/market/marina/ship',
        method: 'POST',
        body: { uuid, destination },
      }),
    }),
    sendMarineVehicle: builder.mutation({
      query: ({ uuid, recipient }) => ({
        url: '/market/marina/send',
        method: 'POST',
        body: { uuid, recipient },
      }),
    }),
    repairMarineVehicle: builder.mutation({
      query: ({ uuid }) => ({
        url: '/market/marina/repair',
        method: 'POST',
        body: { uuid },
      }),
    }),
    scrapMarineVehicle: builder.mutation({
      query: ({ uuid }) => ({
        url: '/market/marina/scrap',
        method: 'POST',
        body: { uuid },
      }),
    }),
    getUserInfo: builder.query({
      query: () => '/user/info',
    }),

    checkPolicy: builder.mutation({
      query: body => ({
        url: '/market/insurance/check',
        method: 'POST',
        body,
      }),
    }),
    getInsurance: builder.query({
      query: () => '/market/insurance/check',
    }),
    buyInsurance: builder.mutation({
      query: body => ({
        url: '/market/insurance/buy',
        method: 'POST',
        body,
      }),
    }),
    getBlackMarketItemListings: builder.query({
      query: () => '/market/blackmarket/items',
    }),
    addBlackMarketItemListing: builder.mutation({
      query: body => ({
        url: '/market/blackmarket/items',
        method: 'POST',
        body,
      }),
    }),
    getBlackMarketPropertyListings: builder.query({
      query: () => '/market/blackmarket/properties',
    }),
    addBlackMarketPropertyListing: builder.mutation({
      query: body => ({
        url: '/market/blackmarket/properties',
        method: 'POST',
        body,
      }),
    }),
    getBlackMarketInsuranceListings: builder.query({
      query: () => '/market/blackmarket/insurance',
    }),
    addBlackMarketInsuranceListing: builder.mutation({
      query: body => ({
        url: '/market/blackmarket/insurance',
        method: 'POST',
        body,
      }),
    }),
    getBlackMarketPointListings: builder.query({
      query: () => '/market/blackmarket/points',
    }),
    addBlackMarketPointListing: builder.mutation({
      query: body => ({
        url: '/market/blackmarket/points',
        method: 'POST',
        body,
      }),
    }),
    getBlackMarketLockpickListings: builder.query({
      query: () => '/market/blackmarket/lockpicks',
    }),
    addBlackMarketLockpickListing: builder.mutation({
      query: body => ({
        url: '/market/blackmarket/lockpicks',
        method: 'POST',
        body,
      }),
    }),
    getBlackMarketUserItems: builder.query({
      query: () => '/market/blackmarket/user-items',
    }),
    getBlackMarketUserProperties: builder.query({
      query: () => '/market/blackmarket/user-properties',
    }),
    getBlackMarketUserInsurance: builder.query({
      query: () => '/market/blackmarket/user-insurance',
    }),
    buyBlackMarketListing: builder.mutation({
      query: body => ({
        url: '/market/blackmarket/buy',
        method: 'POST',
        body,
      }),
    }),
    getRefineryStatus: builder.query({
      query: () => '/market/refinery/status'
    }),
    buyRefineryPass: builder.mutation({
      query: (passType) => ({
        url: '/market/refinery/buy-pass',
        method: 'POST',
        body: { passType }
      })
    }),
    meltCars: builder.mutation({
      query: () => ({
        url: '/market/refinery/melt-cars',
        method: 'POST'
      })
    }),
    
    meltBoats: builder.mutation({
      query: () => ({
        url: '/market/refinery/melt-boats',
        method: 'POST'
      })
    }),
    
    collectStock: builder.mutation({
      query: () => ({
        url: '/market/refinery/collect',
        method: 'POST'
      })
    }),
    
    upgradePass: builder.mutation({
      query: () => ({
        url: '/market/refinery/upgrade',
        method: 'POST'
      })
    }),
    produceKevlar: builder.mutation({
      query: (body) => ({
        url: '/market/refinery/produce-kevlar',
        method: 'POST',
        body
      })
    })
    
    
    








  }),
});

export const {
  useGetItemListingsQuery,
  useAddItemListingMutation,
  useGetPropertyListingsQuery,
  useAddPropertyListingMutation,
  useGetInsuranceListingsQuery,
  useAddInsuranceListingMutation,
  useGetPointListingsQuery,
  useAddPointListingMutation,
  useGetLockpickListingsQuery,
  useAddLockpickListingMutation,
  useBuyItemMutation,
  useGetBarItemsQuery,
  useSendBarItemMutation,

  useGetGarageVehiclesQuery,
  useShipVehicleMutation,
  useSendVehicleMutation,
  useRepairVehicleMutation,
  useScrapVehicleMutation,

  useGetMarineVehiclesQuery,
  useShipMarineVehicleMutation,
  useSendMarineVehicleMutation,
  useRepairMarineVehicleMutation,
  useScrapMarineVehicleMutation,

  useGetUserInfoQuery,
  useCheckPolicyMutation,

  useGetInsuranceQuery,
  useBuyInsuranceMutation,

  useGetBlackMarketItemListingsQuery,
  useAddBlackMarketItemListingMutation,
  useGetBlackMarketPropertyListingsQuery,
  useAddBlackMarketPropertyListingMutation,
  useGetBlackMarketInsuranceListingsQuery,
  useAddBlackMarketInsuranceListingMutation,
  useGetBlackMarketPointListingsQuery,
  useAddBlackMarketPointListingMutation,
  useGetBlackMarketLockpickListingsQuery,
  useAddBlackMarketLockpickListingMutation,
  useGetBlackMarketUserItemsQuery,
  useGetBlackMarketUserPropertiesQuery,
  useGetBlackMarketUserInsuranceQuery,
  useBuyBlackMarketListingMutation,


  useGetRefineryStatusQuery,
  useBuyRefineryPassMutation,
  useMeltCarsMutation,
  useMeltBoatsMutation,
  useCollectStockMutation,
  useUpgradePassMutation,
  useProduceKevlarMutation
} = marketplaceApi;
