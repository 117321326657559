import { apiSlice } from '../auth/apiSlice';

export const crimeApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    // Advanced Crimes
    listAdvancedCrimes: builder.query({
      query: () => '/crimes/listadvancedcrimes',
      providesTags: ['advcrimes_page'],
      refetchOnMountOrArgChange: true,
    }),
    commitAdvancedCrime: builder.mutation({
      query: body => ({
        url: '/crimes/commitadvancedcrime',
        method: 'POST',
        body: { ...body },
      }),
    }),

    // Boat Theft
    listBoatTheftOptions: builder.query({
      query: () => '/crimes/listboattheftoptions',
      providesTags: ['bta_page'],
      refetchOnMountOrArgChange: true,
    }),
    commitBoatTheft: builder.mutation({
      query: body => ({
        url: '/crimes/commitbta',
        method: 'POST',
        body: { ...body },
      }),
    }),

    // Car Theft (GTA)
    listCarTheftOptions: builder.query({
      query: () => '/crimes/listcartheftoptions',
      providesTags: ['gta_page'],
      refetchOnMountOrArgChange: true,
    }),
    commitCarTheft: builder.mutation({
      query: body => ({
        url: '/crimes/commitgta',
        method: 'POST',
        body: { ...body },
      }),
    }),

    // Regular Crimes
    listCrimes: builder.query({
      query: () => '/crimes/listcrimes',
      providesTags: ['crimes_page'],
      refetchOnMountOrArgChange: true,
    }),
    commitCrime: builder.mutation({
      query: body => ({
        url: '/crimes/commitcrime',
        method: 'POST',
        body: { ...body },
      }),
    }),

    // Chase
    commitChase: builder.mutation({
      query: body => ({
        url: '/crimes/chase',
        method: 'POST',
        body: { ...body },
      }),
    }),
    extortionlist: builder.query({
      query: () => '/crimes/extortionlist',
      providesTags: ['families'],
      refetchOnMountOrArgChange: true,
    }),
    extort: builder.mutation({
      query: body => ({
        url: '/crimes/extort',
        method: 'POST',
        body: { ...body },
      }),
    }),

    listDrugs: builder.query({
      query: () => '/crimes/listdrugs',
      providesTags: ['drugs'],
      refetchOnMountOrArgChange: true,
    }),
    drugTransaction: builder.mutation({
      query: body => ({
        url: '/crimes/drugTransaction',
        method: 'POST',
        body: { ...body },
      }),
    }),
    getUserBoats: builder.query({
      query: () => '/crimes/getUserBoats',
      providesTags: ['marina'],
    }),
    startSmuggling: builder.mutation({
      query: body => ({
        url: '/crimes/startSmuggling',
        method: 'POST',
        body,
      }),
    }),
    prisonList: builder.query({
      query: () => '/crimes/prisonlist',
      providesTags: ['prison'],
      refetchOnMountOrArgChange: true,
    }),
    prisonBust: builder.mutation({
      query: body => ({
        url: '/crimes/prisonbust',
        method: 'POST',
        body: { ...body },
      }),
    }),
    isCharacterInSyndicate: builder.query({
      query: () => '/crimes/ischaracterinsyndicate',
      providesTags: ['syndicate'],
      refetchOnMountOrArgChange: true,
    }),
    startSyndicate: builder.mutation({
      query: () => ({
        url: '/crimes/startsyndicate',
        method: 'POST',
      }),
      invalidatesTags: ['syndicate'],
    }),
    getSyndicateInvites: builder.query({
      query: () => '/crimes/syndicateinvites',
      providesTags: ['syndicateInvites'],
    }),

    respondToSyndicateInvite: builder.mutation({
      query: body => ({
        url: '/crimes/respondtoinvite',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['syndicate', 'syndicateInvites'],
    }),
    GetSyndicateData: builder.query({
      query: () => '/crimes/syndicate/data',
      providesTags: ['Syndicate'],
    }),
    InviteToSyndicate: builder.mutation({
      query: data => ({
        url: '/crimes/syndicate/invite',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Syndicate'],
    }),
    LeaveSyndicate: builder.mutation({
      query: () => ({
        url: '/crime/syndicate/leave',
        method: 'POST',
      }),
      invalidatesTags: ['Syndicate'],
    }),
    checkPrisonStatus: builder.query({
      query: () => '/user/prisoncheck',
      refetchOnMountOrArgChange: true,
    }),
    getEncounterStatus: builder.query({
      query: () => '/encounters/status',
      providesTags: ['Encounter'],
      refetchOnMountOrArgChange: true,
    }),
  }),
});

export const {
  useListAdvancedCrimesQuery,
  useCommitAdvancedCrimeMutation,
  useListBoatTheftOptionsQuery,
  useCommitBoatTheftMutation,
  useListCarTheftOptionsQuery,
  useCommitCarTheftMutation,
  useListCrimesQuery,
  useCommitCrimeMutation,
  useCommitChaseMutation,
  useExtortionlistQuery,
  useExtortMutation,
  useListDrugsQuery,
  useDrugTransactionMutation,
  useGetUserBoatsQuery,
  useStartSmugglingMutation,
  usePrisonListQuery,
  usePrisonBustMutation,
  useIsCharacterInSyndicateQuery,
  useStartSyndicateMutation,
  useGetSyndicateInvitesQuery,
  useRespondToSyndicateInviteMutation,
  useInviteToSyndicateMutation,
  useGetSyndicateDataQuery,
  useLeaveSyndicateMutation,
  useCheckPrisonStatusQuery,
  useGetEncounterStatusQuery,
} = crimeApi;
