import React, { useState } from 'react';
import {
  useListDrugsQuery,
  useDrugTransactionMutation,
  useGetUserBoatsQuery,
  useStartSmugglingMutation,
} from '../../api/crime';
import Response from '../../shared-components/response';
import './drugs.css';

const crewOptions = [
  { title: 'Grizzled Old Men', price: 10000, strength: 2 },
  { title: 'Unemployed Sailors', price: 30000, strength: 4 },
  { title: 'Regular Sailors', price: 65000, strength: 6 },
  { title: 'Ex-Navy', price: 90000, strength: 8 },
  { title: 'Corrupt Navy', price: 140000, strength: 10 },
];

const Drugs = () => {
  const [activeTab, setActiveTab] = useState('drugs');
  const { data: drugs, isLoading, isError, refetch } = useListDrugsQuery();
  const [drugTransaction] = useDrugTransactionMutation();
  const [startSmuggling] = useStartSmugglingMutation();
  const { data: userBoats, isLoading: isLoadingBoats } = useGetUserBoatsQuery();
  const [inputValues, setInputValues] = useState({});
  const [alertData, setAlertData] = useState({ message: '', type: '' });
  const [selectedBoat, setSelectedBoat] = useState('');
  const [selectedCrew, setSelectedCrew] = useState('');
  const [selectedDrug, setSelectedDrug] = useState('');

  const handleTabClick = tabName => {
    setActiveTab(tabName);
  };

  const handleInputChange = (drugUuid, value) => {
    setInputValues(prev => ({ ...prev, [drugUuid]: value }));
  };

  const handleTransaction = async type => {
    const transactions = Object.entries(inputValues)
      .filter(([_, value]) => value > 0)
      .map(([drugUuid, amount]) => ({
        drugUuid,
        amount: parseInt(amount),
        type,
      }));

    if (transactions.length > 0) {
      try {
        const result = await drugTransaction({ transactions }).unwrap();
        setInputValues({});
        setAlertData({ message: result.message, type: 'success' });
        refetch();
      } catch (error) {
        console.error('Transaction failed:', error);
        setAlertData({
          message: error.data?.message || 'Transaction failed',
          type: 'danger',
        });
      }
    }
  };

  const handleStartSmuggling = async () => {
    if (!selectedBoat || !selectedCrew || !selectedDrug) {
      setAlertData({
        message: 'Please select a boat, crew, and drug',
        type: 'danger',
      });
      return;
    }

    try {
      const result = await startSmuggling({
        boatUuid: selectedBoat,
        crewStrength: crewOptions[selectedCrew].strength,
        drugUuid: selectedDrug,
        amount: 100, // You might want to add an input for this
      }).unwrap();

      setAlertData({ message: result.message, type: 'success' });
    } catch (error) {
      setAlertData({
        message: error.data?.message || 'Failed to start smuggling operation',
        type: 'danger',
      });
    }
  };

  const calculateTotalPrice = type => {
    return Object.entries(inputValues)
      .reduce((total, [drugUuid, amount]) => {
        const drug = drugs.find(d => d.uuid === drugUuid);
        const price = drug?.drugs_prices[0]?.price || 0;
        return total + (parseInt(amount) || 0) * price;
      }, 0)
      .toLocaleString();
  };

  if (isLoading) return <div>Loading...</div>;
  if (isError) return <div>Error loading drugs list</div>;

  const totalCarried = drugs
    ? drugs.reduce((sum, drug) => sum + (drug.drugs_helds[0]?.amount || 0), 0)
    : 0;
  const maxCarry = 1000; // Replace with actual max carry amount

  return (
    <div>
      <Response message={alertData.message} type={alertData.type} />
      <div className="row">
        <div className="col-lg-1"></div>
        <div className="col-lg-10">
          <div className="family-tabs-container">
            <div className="tabs-wrapper">
              <ul className="nav nav-tabs flex-nowrap">
                <li className="nav-item">
                  <a
                    href="#drugs-tab"
                    onClick={() => handleTabClick('drugs')}
                    className={`nav-link ${activeTab === 'drugs' ? 'active' : ''}`}
                  >
                    Drugs
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="#smuggling-tab"
                    onClick={() => handleTabClick('smuggling')}
                    className={`nav-link ${activeTab === 'smuggling' ? 'active' : ''}`}
                  >
                    Smuggling
                  </a>
                </li>
              </ul>
            </div>
            <div className="tab-content panel rounded-0 p-3 m-0">
              <div
                className={`tab-pane fade ${activeTab === 'drugs' ? 'active show' : ''}`}
                id="drugs-tab"
              >
                <div className="carry-info text-center mb-3">
                  You currently carry {totalCarried} amount, the maximum you can
                  carry is {maxCarry} amount.
                </div>
                <div className="drugs-container">
                  {drugs.map((drug, index) => (
                    <div key={index} className="drug-item">
                      <img
                        src={
                          drug.image
                            ? `/assets/img/drugs/${drug.image}`
                            : 'https://via.placeholder.com/400x100'
                        }
                        alt={drug.title}
                        className="drug-image"
                      />
                      <div className="drug-title">{drug.title}</div>
                      <div className="drug-amount">
                        {drug.drugs_helds[0]?.amount || 0}
                      </div>
                      <div className="drug-price">
                        £{drug.drugs_prices[0]?.price || 'N/A'}
                      </div>
                      <input
                        type="number"
                        className="drug-quantity-input"
                        max="9999"
                        min="0"
                        value={inputValues[drug.uuid] || ''}
                        onChange={e =>
                          handleInputChange(drug.uuid, e.target.value)
                        }
                        style={{
                          position: 'absolute',
                          bottom: '5px',
                          right: '5px',
                          width: '60px',
                          fontSize: '12px',
                          padding: '0',
                          margin: '0',
                          border: '1px solid #ccc',
                          textAlign: 'center',
                        }}
                      />
                    </div>
                  ))}
                </div>
                <div className="drug-actions text-center mt-3">
                  <button
                    className="btn btn-danger btn-sm"
                    style={{ marginRight: '25px' }}
                    onClick={() => handleTransaction('sell')}
                  >
                    Sell for £{calculateTotalPrice('sell')}
                  </button>
                  <button
                    className="btn btn-success btn-sm"
                    style={{ marginLeft: '25px' }}
                    onClick={() => handleTransaction('buy')}
                  >
                    Buy for £{calculateTotalPrice('buy')}
                  </button>
                </div>
              </div>
              <div
                className={`tab-pane fade ${activeTab === 'smuggling' ? 'active show' : ''}`}
                id="smuggling-tab"
              >
                <h3>Smuggling</h3>
                <div className="form-group mb-4">
                  <label htmlFor="boatSelect">Select a Vehicle:</label>
                  <select
                    className="form-control"
                    id="boatSelect"
                    value={selectedBoat}
                    onChange={e => setSelectedBoat(e.target.value)}
                  >
                    <option value="">Select a boat</option>
                    {userBoats &&
                      userBoats.map(boat => (
                        <option key={boat.uuid} value={boat.uuid}>
                          {boat.vehicle.title} ({boat.vehicle.type}) - Value: £
                          {boat.value}
                        </option>
                      ))}
                  </select>
                </div>

                <div className="form-group mb-4">
                  <label htmlFor="crewSelect">Hire a Crew:</label>
                  <select
                    className="form-control"
                    id="crewSelect"
                    value={selectedCrew}
                    onChange={e => setSelectedCrew(e.target.value)}
                  >
                    <option value="">Select a crew</option>
                    {crewOptions.map((crew, index) => (
                      <option key={index} value={index}>
                        {crew.title} - £{crew.price.toLocaleString()} (Strength:{' '}
                        {crew.strength})
                      </option>
                    ))}
                  </select>
                </div>

                <div className="form-group mb-4">
                  <label htmlFor="drugSelect">Choose a Drug:</label>
                  <select
                    className="form-control"
                    id="drugSelect"
                    value={selectedDrug}
                    onChange={e => setSelectedDrug(e.target.value)}
                  >
                    <option value="">Select a drug</option>
                    {drugs &&
                      drugs.map(drug => (
                        <option key={drug.uuid} value={drug.uuid}>
                          {drug.title} - £{drug.drugs_prices[0]?.price || 'N/A'}
                        </option>
                      ))}
                  </select>
                </div>

                <div className="text-center mt-4">
                  <button
                    className="btn btn-primary"
                    onClick={handleStartSmuggling}
                  >
                    Start Smuggling
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-1"></div>
      </div>
    </div>
  );
};

export default Drugs;
