import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  useGetPropertyDetailsQuery,
  useIsPropertyOwnerMutation,
  usePlaySlotsMutation,
} from '../../api/properties';
import PropertyPanel from './propertyPanel';
import Response from '../../shared-components/response';
import { formatNumber } from '../../shared-components/numberFormat';

const Slots = () => {
  const { data: propertyDetails, isLoading } =
    useGetPropertyDetailsQuery('slots');
  const [checkOwnership, { data: ownershipData }] =
    useIsPropertyOwnerMutation();
  const [bet, setBet] = useState('');
  const [alertData, setAlertData] = useState({ message: '', type: '' });
  const [playSlots, { data: slotsResult }] = usePlaySlotsMutation();
  const [slotResult, setSlotResult] = useState(null);

  const handleSubmit = async () => {
    try {
      const result = await playSlots({ bet }).unwrap();
      setSlotResult(result);
      setAlertData({ message: result.message, type: result.type });
    } catch (error) {
      console.error('Error playing slots:', error);
      setAlertData({ message: 'Error playing slots', type: 'danger' });
    }
  };

  useEffect(() => {
    checkOwnership({ property: 'slots' });
  }, [checkOwnership]);

  if (isLoading) return <div>Loading...</div>;

  return (
    <div>
      <Response
        message={alertData.message}
        type={alertData.type}
        slotsResult={slotsResult?.result}
      />

      <div className="tab-content rounded-0 m-0">
        {ownershipData?.isOwner ? (
          <PropertyPanel property="slots" />
        ) : propertyDetails.owner === null ? (
          <div>
            <p>
              Nobody owns this property. You can pick it up at the&nbsp;
              <Link to="/game/general/properties">locations page</Link>.
            </p>
          </div>
        ) : (
          <div className="row">
            <div className="col-lg-4"></div>
            <div className="col-lg-4">
              {slotsResult?.result && (
                <div className="card mt-3">
                  <div className="card-body">
                    <h5 className="card-title">Slot Results</h5>
                    <div className="d-flex justify-content-around">
                      {slotsResult.result.symbols.map((symbol, index) => (
                        <div key={index} className="slot-symbol">
                          <img
                            src={`assets/img/slots/${symbol}.jpg`}
                            alt={symbol}
                            width="80"
                            height="80"
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}

              <div className="card">
                <div className="card-body">
                  <h3 className="card-title text-center">Slots</h3>
                  <div className="d-flex mb-3">
                    <input
                      type="number"
                      className="form-control mr-2"
                      value={bet}
                      onChange={e => setBet(e.target.value)}
                      placeholder="Enter bet amount"
                    />
                    <button onClick={handleSubmit} className="btn btn-primary">
                      Spin
                    </button>
                  </div>
                  <hr />
                  <p>Max bet: £{formatNumber(propertyDetails.maxBet)}</p>
                  <hr />
                  <p>Jackpot: {formatNumber(propertyDetails.jackpot)}</p>

                  <p>
                    Owned by:
                    {propertyDetails.owner ? (
                      <Link
                        to={`/game/general/userprofile/${propertyDetails.owner}`}
                      >
                        {propertyDetails.owner}
                      </Link>
                    ) : (
                      'None'
                    )}
                  </p>
                </div>
              </div>
              <div className="card mt-3">
                <div className="card-body">
                  <h4>Winning Combinations</h4>
                  <table className="table table-sm">
                    <thead>
                      <tr>
                        <th>Combination</th>
                        <th>Payout</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>🔴🔴🔴</td>
                        <td>100x Bet</td>
                      </tr>
                      <tr>
                        <td>🔵🔵🔵</td>
                        <td>50x Bet</td>
                      </tr>
                      <tr>
                        <td>🍒🍒🍒</td>
                        <td>30x Bet</td>
                      </tr>
                      <tr>
                        <td>🔫🔫🔫</td>
                        <td>30x Bet</td>
                      </tr>
                      <tr>
                        <td>🔔🔔🔔</td>
                        <td>30x Bet</td>
                      </tr>
                      <tr>
                        <td>3️⃣3️⃣3️⃣</td>
                        <td>30x Bet</td>
                      </tr>
                      <tr>
                        <td>2️⃣2️⃣2️⃣</td>
                        <td>20x Bet</td>
                      </tr>
                      <tr>
                        <td>1️⃣1️⃣1️⃣</td>
                        <td>10x Bet</td>
                      </tr>
                      <tr>
                        <td>🔵🔴🔵</td>
                        <td>5x Bet</td>
                      </tr>
                      <tr>
                        <td>3️⃣1️⃣2️⃣</td>
                        <td>2x Bet</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Slots;
