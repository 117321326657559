import React, { useState, useEffect } from 'react';
import {
  useGetUpdatesQuery,
  useGetGameStatsQuery,
  useGetUserStatsQuery,
  useGetArticlesQuery,
  useCreateArticleMutation,
  useGetUserActivityQuery,
  useGetCharacterStatsQuery,
  useClaimDailyRewardMutation,
  useClaimStarterPackMutation,
  useGetUpgradeOptionsQuery,
  usePerformUpgradeMutation,
} from '../../api/general';
import './dashboard.css';
import ReactQuill from 'react-quill'; // You'll need to install this package
import 'react-quill/dist/quill.snow.css';
import { useNavigate } from 'react-router-dom';
import { formatNumber } from '../../shared-components/numberFormat';
import { RoleGuard, ROLES } from '../../components/RoleGuard';


function UpgradeModal({ show, onClose, upgrade, onUpgrade }) {
  const { data: upgradeOptions, isLoading } = useGetUpgradeOptionsQuery(
    upgrade?.type ?? ''
  );
  const [performUpgrade] = usePerformUpgradeMutation();

  if (!show || !upgrade) return null;

  const currentUpgrade = upgradeOptions?.find(option =>
    upgrade.type === 'travelCar'
      ? option.name === upgrade.value
      : option.title === upgrade.value
  );
  const nextUpgrade = upgradeOptions?.find(
    option => option.costMoney > (currentUpgrade?.costMoney ?? 0)
  );

  const displayName = option =>
    upgrade.type === 'travelCar' ? option.name : option.title;

  const handleUpgrade = async () => {
    try {
      await performUpgrade({ type: upgrade.type, upgradeId: nextUpgrade.uuid });
      onUpgrade();
    } catch (error) {
      console.error('Upgrade failed:', error);
    }
  };

  return (
    <div
      className="modal"
      style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header justify-content-center position-relative">
            <h5 className="modal-title">Upgrade {upgrade.type}</h5>
            <button
              type="button"
              className="close position-absolute"
              style={{ right: '10px', top: '10px' }}
              onClick={onClose}
            >
              <span>&times;</span>
            </button>
          </div>
          <div className="modal-body text-center">
            <p>
              Current: {currentUpgrade ? displayName(currentUpgrade) : 'None'}
            </p>
            {nextUpgrade && (
              <>
                <p>Next: {displayName(nextUpgrade)}</p>
                <button onClick={handleUpgrade} className="btn btn-primary">
                  Upgrade (£{formatNumber(nextUpgrade.costMoney)})
                </button>
              </>
            )}
            {!nextUpgrade && <p>Max level reached</p>}
          </div>
        </div>
      </div>
    </div>
  );
}

function GameUpdates() {
  const [fetchUpdates, setFetchUpdates] = useState(false);
  const {
    data: updates,
    isLoading,
    error,
  } = useGetUpdatesQuery(undefined, {
    skip: !fetchUpdates,
  });

  useEffect(() => {
    setFetchUpdates(true);
  }, []);

  if (isLoading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  return (
    <div>
      <h3 className="mt-10px">Updates</h3>
      <table className="table">
        <thead>
          <tr>
            <th>Posted by</th>
            <th>Update Title</th>
            <th>Update Description</th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(updates) && updates.length > 0 ? (
            updates.map(update => (
              <tr key={update.uuid}>
                <td>{update.username}</td>
                <td>{update.update}</td>
                <td>{update.description}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={3}>No updates available</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}

function GameStats({ activeStatTab, setActiveStatTab }) {
  const {
    data: gameStats,
    isLoading: statsLoading,
    error: statsError,
  } = useGetGameStatsQuery();
  if (statsLoading) return <p>Loading game stats...</p>;
  if (statsError) return <p>Error loading game stats: {statsError.message}</p>;

  const handleStatTabClick = tabName => {
    setActiveStatTab(tabName);
  };

  return (
    <div>
      <h3 className="mt-10px">Game Stats</h3>
      <ul className="nav nav-pills nav-justified mb-3">
        <li className="nav-item">
          <a
            href="#user-stats"
            onClick={() => handleStatTabClick('userStats')}
            className={`nav-link ${activeStatTab === 'userStats' ? 'active' : ''}`}
          >
            User Stats
          </a>
        </li>
        <li className="nav-item">
          <a
            href="#money-stats"
            onClick={() => handleStatTabClick('moneyStats')}
            className={`nav-link ${activeStatTab === 'moneyStats' ? 'active' : ''}`}
          >
            Money Stats
          </a>
        </li>
        <li className="nav-item">
          <a
            href="#mission-stats"
            onClick={() => handleStatTabClick('missionStats')}
            className={`nav-link ${activeStatTab === 'missionStats' ? 'active' : ''}`}
          >
            Mission Stats
          </a>
        </li>
      </ul>
      <div className="tab-content">
        <div
          className={`tab-pane fade ${activeStatTab === 'userStats' ? 'show active' : ''}`}
          id="user-stats"
        >
          <table className="table">
            <tbody>
              <tr>
                <td>Total Users:</td>
                <td>{gameStats.usersTotal}</td>
              </tr>
              <tr>
                <td>Alive Users:</td>
                <td>{gameStats.usersAlive}</td>
              </tr>
              <tr>
                <td>Dead Users:</td>
                <td>{gameStats.usersDead}</td>
              </tr>
              <tr>
                <td>Banned Users:</td>
                <td>{gameStats.usersBanned}</td>
              </tr>
              <tr>
                <td>Active Users:</td>
                <td>{gameStats.usersActive}</td>
              </tr>
              <tr>
                <td>Active Staff:</td>
                <td>{gameStats.usersActiveStaff}</td>
              </tr>
              <tr>
                <td>Kill Attempts:</td>
                <td>{gameStats.usersAttempts}</td>
              </tr>
              <tr>
                <td>Suicides:</td>
                <td>{gameStats.usersSuicides}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          className={`tab-pane fade ${activeStatTab === 'moneyStats' ? 'show active' : ''}`}
          id="money-stats"
        >
          <table className="table">
            <tbody>
              <tr>
                <td>Total on Hand:</td>
                <td>£{formatNumber(gameStats.moneyUsers)}</td>
              </tr>
              <tr>
                <td>Total in Bank:</td>
                <td>£{formatNumber(gameStats.moneyBank)}</td>
              </tr>
              <tr>
                <td>Total in Families:</td>
                <td>£{formatNumber(gameStats.moneyFamily)}</td>
              </tr>
              <tr>
                <td>Total in ATM:</td>
                <td>£{formatNumber(gameStats.moneyAtm)}</td>
              </tr>
              <tr>
                <td>Total in Savings:</td>
                <td>£{formatNumber(gameStats.moneySavings)}</td>
              </tr>
              <tr>
                <td>Total in Syndicates:</td>
                <td>£{formatNumber(gameStats.moneySyndicates)}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          className={`tab-pane fade ${activeStatTab === 'missionStats' ? 'show active' : ''}`}
          id="mission-stats"
        >
          <table className="table">
            <tbody>
              <tr>
                <td>Total Missions Completed:</td>
                <td>{gameStats.totalMissionsCompleted}</td>
              </tr>
              <tr>
                <td>Total FMJ's Bought:</td>
                <td>{gameStats.totalFMJsBought}</td>
              </tr>
              <tr>
                <td>Total Vehicles Shipped:</td>
                <td>{gameStats.totalVehiclesShipped}</td>
              </tr>
              <tr>
                <td>Total Drugs Moved:</td>
                <td>{gameStats.totalDrugsMoved}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

function Activity({ activeActivityTab, setActiveActivityTab }) {
  const { data: userActivity, isLoading, error } = useGetUserActivityQuery();

  const handleActivityTabClick = tabName => {
    setActiveActivityTab(tabName);
  };

  if (isLoading) return <p>Loading activity data...</p>;
  if (error) return <p>Error loading activity data: {error.message}</p>;

  return (
    <div>
      <h3 className="mt-10px">Activity</h3>
      <ul className="nav nav-pills nav-justified mb-3">
        <li className="nav-item">
          <a
            href="#kill-stats"
            onClick={() => handleActivityTabClick('kill')}
            className={`nav-link ${activeActivityTab === 'kill' ? 'active' : ''}`}
          >
            Kill
          </a>
        </li>
        <li className="nav-item">
          <a
            href="#money-stats"
            onClick={() => handleActivityTabClick('money')}
            className={`nav-link ${activeActivityTab === 'money' ? 'active' : ''}`}
          >
            Money
          </a>
        </li>
        <li className="nav-item">
          <a
            href="#activity-stats"
            onClick={() => handleActivityTabClick('activity')}
            className={`nav-link ${activeActivityTab === 'activity' ? 'active' : ''}`}
          >
            Activity
          </a>
        </li>
      </ul>
      <div className="tab-content">
        <div
          className={`tab-pane fade ${activeActivityTab === 'kill' ? 'show active' : ''}`}
          id="kill-stats"
        >
          <table className="table">
            <tbody>
              <tr>
                <td>Spent on Kevlar:</td>
                <td>£{formatNumber(userActivity.spentOnKevlar)}</td>
              </tr>
              <tr>
                <td>Kevlar Purchased:</td>
                <td>{userActivity.kevlarPurchased}</td>
              </tr>
              <tr>
                <td>Kevlars Lost:</td>
                <td>{userActivity.kevlarsLost}</td>
              </tr>
              <tr>
                <td>Spent on Bullets:</td>
                <td>£{formatNumber(userActivity.spentOnBullets)}</td>
              </tr>
              <tr>
                <td>Bullets Purchased:</td>
                <td>{userActivity.bulletsPurchased}</td>
              </tr>
              <tr>
                <td>Bullets from Melting:</td>
                <td>{userActivity.bulletsFromMelting}</td>
              </tr>
              <tr>
                <td>Bullets from Missions Coins:</td>
                <td>{userActivity.bulletsFromMissionsCoins}</td>
              </tr>
              <tr>
                <td>Total Bullets Shot:</td>
                <td>{userActivity.totalBulletsShot}</td>
              </tr>
              <tr>
                <td>Encounter Bullets Shot:</td>
                <td>{userActivity.encounterBulletsShot}</td>
              </tr>
              <tr>
                <td>Bullets Shot at You:</td>
                <td>{userActivity.bulletsShotAtYou}</td>
              </tr>
              <tr>
                <td>Bullets Gained from Mugging:</td>
                <td>{userActivity.bulletsGainedFromMugging}</td>
              </tr>
              <tr>
                <td>Lost from Mugging:</td>
                <td>{userActivity.lostFromMugging}</td>
              </tr>
              <tr>
                <td>Family Donations:</td>
                <td>£{formatNumber(userActivity.familyDonations)}</td>
              </tr>
              <tr>
                <td>Family Withdrawals:</td>
                <td>£{formatNumber(userActivity.familyWithdrawals)}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          className={`tab-pane fade ${activeActivityTab === 'money' ? 'show active' : ''}`}
          id="money-stats"
        >
          <table className="table">
            <tbody>
              <tr>
                <td>Extortion Gain:</td>
                <td>£{formatNumber(userActivity.extortionGain)}</td>
              </tr>
              <tr>
                <td>Extortion Loss:</td>
                <td>£{formatNumber(userActivity.extortionLoss)}</td>
              </tr>
              <tr>
                <td>Family Donations:</td>
                <td>£{formatNumber(userActivity.familyDonations)}</td>
              </tr>
              <tr>
                <td>Family Withdrawals:</td>
                <td>£{userActivity.familyWithdrawals}</td>
              </tr>
              <tr>
                <td>Casino Betting Profit:</td>
                <td>£{formatNumber(userActivity.casinoBettingProfit)}</td>
              </tr>
              <tr>
                <td>Crime Profit:</td>
                <td>£{formatNumber(userActivity.crimeProfit)}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          className={`tab-pane fade ${activeActivityTab === 'activity' ? 'show active' : ''}`}
          id="activity-stats"
        >
          <table className="table">
            <tbody>
              <tr>
                <td>Contracts Completed:</td>
                <td>{userActivity.contractsCompleted}</td>
              </tr>
              <tr>
                <td>Contracts Failed:</td>
                <td>{userActivity.contractsFailed}</td>
              </tr>
              <tr>
                <td>Crimes:</td>
                <td>{userActivity.crimes}</td>
              </tr>
              <tr>
                <td>Advanced Crimes:</td>
                <td>{userActivity.advancedCrimes}</td>
              </tr>
              <tr>
                <td>Car Theft:</td>
                <td>{userActivity.carTheft}</td>
              </tr>
              <tr>
                <td>Boat Theft:</td>
                <td>{userActivity.boatTheft}</td>
              </tr>
              <tr>
                <td>Extortion:</td>
                <td>{userActivity.extortion}</td>
              </tr>
              <tr>
                <td>Sent Messages:</td>
                <td>{userActivity.sentMessages}</td>
              </tr>
              <tr>
                <td>Forum Posts:</td>
                <td>{userActivity.forumPosts}</td>
              </tr>
              <tr>
                <td>Prison Busts:</td>
                <td>{userActivity.prisonBusts}</td>
              </tr>
              <tr>
                <td>Lockpicks Found:</td>
                <td>{userActivity.lockpicksFound}</td>
              </tr>
              <tr>
                <td>OC's Completed:</td>
                <td>{userActivity.ocsCompleted}</td>
              </tr>
              <tr>
                <td>OC's Failed:</td>
                <td>{userActivity.ocsFailed}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

function RewardPopup({ show, onClose, rewards }) {
  if (!show) return null;

  return (
    <div
      className="modal"
      style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Reward Claimed!</h5>
            <button type="button" className="close" onClick={onClose}>
              <span>&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <p>You have received:</p>
            <ul>
              {rewards.money && <li>£{rewards.money}</li>}
              {rewards.bullets && <li>{rewards.bullets} bullets</li>}
              {rewards.kevlars && <li>{rewards.kevlars} kevlars</li>}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

function YourCharacter() {
  const {
    data: characterStats,
    isLoading,
    error,
    refetch,
  } = useGetCharacterStatsQuery();
  const [claimDailyReward] = useClaimDailyRewardMutation();
  const [claimStarterPack] = useClaimStarterPackMutation();
  const [starterPackClaimed, setStarterPackClaimed] = useState(false);
  const [dailyRewardClaimed, setDailyRewardClaimed] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [popupRewards, setPopupRewards] = useState({});
  const navigate = useNavigate();
  const [timers, setTimers] = useState({});
  const [selectedUpgrade, setSelectedUpgrade] = useState(null);
  const { data: upgradeOptions } = useGetUpgradeOptionsQuery('weapon');

  const handleUpgradeClick = upgrade => {
    setSelectedUpgrade(upgrade);
  };

  const handleCloseModal = () => {
    setSelectedUpgrade(null);
  };

  const handleUpgradeComplete = () => {
    refetch();
    handleCloseModal();
  };

  useEffect(() => {
    if (characterStats) {
      setStarterPackClaimed(
        characterStats.starterPackClaimed === true ||
          characterStats.starterPackClaimed === 1
      );
      setDailyRewardClaimed(!characterStats.canClaimDaily);
    }
  }, [characterStats]);

  useEffect(() => {
    if (characterStats && characterStats.timers) {
      setTimers(characterStats.timers);
    }
  }, [characterStats]);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimers(prevTimers => {
        const updatedTimers = { ...prevTimers };
        Object.keys(updatedTimers).forEach(key => {
          if (updatedTimers[key].time > 0) {
            updatedTimers[key] = {
              ...updatedTimers[key],
              time: updatedTimers[key].time - 1,
            };
          }
        });
        return updatedTimers;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const handleTimerClick = route => {
    navigate(route);
  };

  const handleClaimStarterPack = async () => {
    try {
      const result = await claimStarterPack().unwrap();
      if (result.type === 'success') {
        setStarterPackClaimed(true);
        setPopupRewards(result.rewards);
        setShowPopup(true);
        refetch();
      }
    } catch (err) {
      console.error('Failed to claim starter pack:', err);
    }
  };

  const handleClaimDailyReward = async () => {
    try {
      const result = await claimDailyReward().unwrap();
      if (result.type === 'success') {
        setDailyRewardClaimed(true);
        setPopupRewards(result.rewards);
        setShowPopup(true);
        refetch();
      }
    } catch (err) {
      console.error('Failed to claim daily reward:', err);
    }
  };

  if (isLoading) return <p>Loading character stats...</p>;
  if (error) return <p>Error loading character stats: {error.message}</p>;

  const formatTime = seconds => {
    if (seconds === 0) return 'READY';
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return `${hours}h ${minutes}m ${remainingSeconds}s`;
  };

  if (isLoading) return <p>Loading character stats...</p>;
  if (error) return <p>Error loading character stats: {error.message}</p>;

  const upgrades = characterStats
    ? [
        {
          type: 'weapon',
          value: characterStats.upgrades[0].value,
          image: 'https://placehold.co/220x220.png',
        },
        {
          type: 'protection',
          value: characterStats.upgrades[1].value,
          image: 'https://placehold.co/220x220.png',
        },
        {
          type: 'garage',
          value: characterStats.upgrades[2].value,
          image: 'https://placehold.co/220x220.png',
        },
        {
          type: 'marina',
          value: characterStats.upgrades[3].value,
          image: 'https://placehold.co/220x220.png',
        },
        {
          type: 'travelCar',
          value: characterStats.upgrades[4].value,
          image: 'https://placehold.co/220x220.png',
        },
      ]
    : [];

  return (
    <div className="character-content">
      <div
        className="card-area"
        style={{
          height: starterPackClaimed && dailyRewardClaimed ? '0' : '140px',
        }}
      >
        {!starterPackClaimed && (
          <div className="col-md-6 card-container">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Starter Pack</h5>
                <p className="card-text">
                  Welcome to UK-MAFIA! Collect your starter pack to begin your
                  journey.
                </p>
                <button
                  onClick={handleClaimStarterPack}
                  className="btn btn-primary"
                >
                  Get Starter Pack
                </button>
              </div>
            </div>
          </div>
        )}
        {!dailyRewardClaimed && (
          <div className="col-md-6 card-container">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Daily Reward</h5>
                <p className="card-text">
                  Collect your daily reward for bullets, kevlar, or money!
                </p>
                <button
                  onClick={handleClaimDailyReward}
                  className="btn btn-primary"
                >
                  Claim Reward
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      <RewardPopup
        show={showPopup}
        onClose={() => setShowPopup(false)}
        rewards={popupRewards}
      />

      <div className="character-content">
        <div className="wait-times-container">
          <div className="wait-times-grid">
            {Object.entries(timers).map(([activity, data]) => (
              <button
                key={activity}
                className={`wait-time-item ${data.time === 0 ? 'ready' : ''}`}
                onClick={() => handleTimerClick(data.route)}
              >
                <span className="wait-time-activity">{activity}</span>
                <span className="wait-time-value">{formatTime(data.time)}</span>
              </button>
            ))}
          </div>
        </div>

        <div className="upgrades-section">
          <div className="upgrades-row">
            {upgrades.map((upgrade, index) => (
              <div
                key={index}
                className="upgrade-item"
                onClick={() => handleUpgradeClick(upgrade)}
              >
                <div className="upgrade-banner">{upgrade.value}</div>
                <img
                  src={upgrade.image}
                  alt={upgrade.type}
                  className="upgrade-image"
                  style={{ width: '220px', height: '220px' }}
                />
                <div className="upgrade-value">{upgrade.type}</div>
              </div>
            ))}
          </div>
        </div>

        <UpgradeModal
          show={selectedUpgrade !== null}
          onClose={handleCloseModal}
          upgrade={selectedUpgrade}
          onUpgrade={handleUpgradeComplete}
        />
      </div>
    </div>
  );
}

function News() {
  const [selectedArticle, setSelectedArticle] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showNewArticleModal, setShowNewArticleModal] = useState(false);
  const [newArticle, setNewArticle] = useState({
    title: '',
    subject: '',
    content: '',
  });

  const { data: articles } = useGetArticlesQuery();
  const [createArticle] = useCreateArticleMutation();

  const handleArticleClick = article => {
    setSelectedArticle(article);
    setShowModal(true);
  };

  const handleNewArticleSubmit = async () => {
    await createArticle(newArticle);
    setShowNewArticleModal(false);
    setNewArticle({ title: '', subject: '', content: '' });
  };

  function parseContent(content) {
    const replaceImageTags = text => {
      // Replace custom image tags with actual img elements
      return text.replace(
        /\[img\s+src="([^"]+)"\]\[\/img\]/g,
        '<img src="$1" alt="Article image" style="max-width: 100%; width: 100%;" />'
      );
    };

    // Apply the replacement
    const parsedContent = replaceImageTags(content);

    // Return the parsed content
    return parsedContent;
  }

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ size: ['small', false, 'large', 'huge'] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ color: [] }, { background: [] }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ align: [] }],
      ['clean'],
    ],
  };

  return (
    <div>
      <h3 className="mt-10px">News</h3>
      <RoleGuard roles={[ROLES.Admin, ROLES.Editor]}>
      <button
        className="btn btn-primary float-right"
        onClick={() => setShowNewArticleModal(true)}
      >
        New Article
      </button>
      </RoleGuard>
      <div className="list-group">
        {articles &&
          articles.map(article => (
            <div
              key={article.id}
              className="list-group-item list-group-item-action"
              onClick={() => handleArticleClick(article)}
              style={{ cursor: 'pointer' }}
            >
              <div className="d-flex w-100 justify-content-between">
                <h5 className="mb-1">{article.title}</h5>
                <small>
                  Written by {article.authorName} on{' '}
                  {new Date(article.date).toLocaleDateString()}
                </small>
              </div>
              <p className="mb-1">{article.subject}</p>
            </div>
          ))}
      </div>

      {showModal && selectedArticle && (
        <div
          className="modal"
          style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }}
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header justify-content-center">
                <h4 className="modal-title text-center">
                  {selectedArticle.title}
                </h4>
              </div>
              <div className="modal-body">
                <div className="text-center mb-3">
                  <h6>{selectedArticle.subject}</h6>
                  <hr />
                </div>
                <div
                  className="ql-editor"
                  dangerouslySetInnerHTML={{
                    __html: parseContent(selectedArticle.content),
                  }}
                />
              </div>
              <div className="modal-footer">
                <small className="mr-auto">
                  Written by {selectedArticle.authorName} on{' '}
                  {new Date(selectedArticle.date).toLocaleDateString()}
                </small>
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setShowModal(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {showNewArticleModal && (
        <div
          className="modal"
          style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }}
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">New Article</h5>
                <button
                  type="button"
                  className="close"
                  onClick={() => setShowNewArticleModal(false)}
                >
                  <span>&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <input
                  type="text"
                  className="form-control mb-2"
                  placeholder="Title"
                  value={newArticle.title}
                  onChange={e =>
                    setNewArticle({ ...newArticle, title: e.target.value })
                  }
                />
                <input
                  type="text"
                  className="form-control mb-2"
                  placeholder="Subject"
                  value={newArticle.subject}
                  onChange={e =>
                    setNewArticle({ ...newArticle, subject: e.target.value })
                  }
                />
                <ReactQuill
                  value={newArticle.content}
                  onChange={content =>
                    setNewArticle({ ...newArticle, content })
                  }
                  modules={modules}
                />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setShowNewArticleModal(false)}
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleNewArticleSubmit}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

function YourStats() {
  const { data: userStats, isLoading, error } = useGetUserStatsQuery();

  if (isLoading) return <p>Loading your stats...</p>;
  if (error) return <p>Error loading your stats: {error.message}</p>;

  return (
    <div>
      <h3 className="mt-10px">Your Stats</h3>
      <table className="table">
        <tbody>
          <tr>
            <td>Email:</td>
            <td>{userStats.email}</td>
          </tr>
          <tr>
            <td>Referral Link:</td>
            <td>{userStats.referralLink}</td>
          </tr>
          <tr>
            <td>Signup Date:</td>
            <td>{new Date(userStats.signupDate).toLocaleDateString()}</td>
          </tr>
          <tr>
            <td>Money in Bank:</td>
            <td>£{formatNumber(userStats.bankBalance)}</td>
          </tr>
          <tr>
            <td>Kills:</td>
            <td>{userStats.kills}</td>
          </tr>
          <tr>
            <td>Health:</td>
            <td>{userStats.health}%</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

const achievementData = [
  { id: 1, name: 'Crimes', icon: '🔪', progress: 70, total: 100 },
  { id: 2, name: 'Advanced Crimes', icon: '🦹', progress: 30, total: 50 },
  { id: 3, name: 'Car Theft', icon: '🚗', progress: 20, total: 30 },
  { id: 4, name: 'Boat Theft', icon: '🚤', progress: 5, total: 10 },
  { id: 5, name: 'Extortion', icon: '💰', progress: 15, total: 25 },
];

const Achievements = () => (
  <div className="achievement-cards">
    {achievementData.map(achievement => (
      <div key={achievement.id} className="achievement-card">
        <div className="achievement-icon">{achievement.icon}</div>
        <h3>{achievement.name}</h3>
        <progress
          value={achievement.progress}
          max={achievement.total}
        ></progress>
        <p>
          {achievement.progress} / {achievement.total}
        </p>
      </div>
    ))}
  </div>
);

function Dashboard() {
  const [activeTab, setActiveTab] = useState('updates');
  const [activeStatTab, setActiveStatTab] = useState('userStats');
  const [activeActivityTab, setActiveActivityTab] = useState('kill');

  const handleTabClick = tabName => {
    setActiveTab(tabName);
  };

  return (
    <div className="family-tabs-container">
      <div className="tabs-wrapper">
        <ul className="nav nav-tabs flex-nowrap">
          <li className="nav-item">
            <a
              href="#updates-tab"
              onClick={() => handleTabClick('updates')}
              className={`nav-link ${activeTab === 'updates' ? 'active' : ''}`}
            >
              Updates
            </a>
          </li>
          <li className="nav-item">
            <a
              href="#news-tab"
              onClick={() => handleTabClick('news')}
              className={`nav-link ${activeTab === 'news' ? 'active' : ''}`}
            >
              News
            </a>
          </li>
          <li className="nav-item">
            <a
              href="#game-stats-tab"
              onClick={() => handleTabClick('gameStats')}
              className={`nav-link ${activeTab === 'gameStats' ? 'active' : ''}`}
            >
              Game Stats
            </a>
          </li>
          <li className="nav-item">
            <a
              href="#your-character-tab"
              onClick={() => handleTabClick('yourCharacter')}
              className={`nav-link ${activeTab === 'yourCharacter' ? 'active' : ''}`}
            >
              Your Character
            </a>
          </li>
          <li className="nav-item">
            <a
              href="#activity-tab"
              onClick={() => handleTabClick('activity')}
              className={`nav-link ${activeTab === 'activity' ? 'active' : ''}`}
            >
              Activity
            </a>
          </li>
          <li className="nav-item">
            <a
              href="#achievements-tab"
              onClick={() => handleTabClick('achievements')}
              className={`nav-link ${activeTab === 'achievements' ? 'active' : ''}`}
            >
              Achievements
            </a>
          </li>
          <li className="nav-item">
            <a
              href="#your-stats-tab"
              onClick={() => handleTabClick('yourStats')}
              className={`nav-link ${activeTab === 'yourStats' ? 'active' : ''}`}
            >
              Your Stats
            </a>
          </li>
        </ul>
      </div>
      <div className="tab-content panel rounded-0 p-3 m-0">
        <div
          className={`tab-pane fade ${activeTab === 'updates' ? 'active show' : ''}`}
          id="updates-tab"
        >
          {activeTab === 'updates' && <GameUpdates />}
        </div>
        <div
          className={`tab-pane fade ${activeTab === 'news' ? 'active show' : ''}`}
          id="news-tab"
        >
          {activeTab === 'news' && <News />}
        </div>
        <div
          className={`tab-pane fade ${activeTab === 'gameStats' ? 'active show' : ''}`}
          id="game-stats-tab"
        >
          {activeTab === 'gameStats' && (
            <GameStats
              activeStatTab={activeStatTab}
              setActiveStatTab={setActiveStatTab}
            />
          )}
        </div>
        <div
          className={`tab-pane fade ${activeTab === 'yourCharacter' ? 'active show' : ''}`}
          id="your-character-tab"
        >
          {activeTab === 'yourCharacter' && <YourCharacter />}
        </div>
        <div
          className={`tab-pane fade ${activeTab === 'activity' ? 'active show' : ''}`}
          id="activity-tab"
        >
          {activeTab === 'activity' && (
            <Activity
              activeActivityTab={activeActivityTab}
              setActiveActivityTab={setActiveActivityTab}
            />
          )}
        </div>
        <div
          className={`tab-pane fade ${activeTab === 'achievements' ? 'active show' : ''}`}
          id="achievements-tab"
        >
          <h3 className="mt-10px">Achievements</h3>
          {activeTab === 'achievements' && <Achievements />}
        </div>
        <div
          className={`tab-pane fade ${activeTab === 'yourStats' ? 'active show' : ''}`}
          id="your-stats-tab"
        >
          {activeTab === 'yourStats' && <YourStats />}
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
