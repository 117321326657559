import React, { useState, useEffect } from 'react';
import './encounters.css';
import Response from '../../shared-components/response';

import {
  useGetEncounterStatusQuery,
  useShootMutation,
  useRunMutation,
  useSendInviteMutation,
  useAcceptInviteMutation,
  useDeclineInviteMutation,
  useGetEncounterInvitesQuery,
  useGetHeldEncounterItemsQuery,
  useEncounterItemMutation,
} from '../../api/encounters';

const MOCK_USER = {
  encounter_bulletshot: 1250,
  en_money: 1500000,
  en_rankpoints: 5000,
  en_points: 100,
  en_bullets: 500,
  en_kevlar: 25,
};

const MOCK_STATS = {
  totalEncounters: 45,
  totalMobsDefeated: 35,
  totalFailed: 10,
  totalCommon: 20,
  totalRare: 8,
  totalBoss: 4,
  totalDon: 2,
  totalCop: 1,
  totalSpecial: 3,
};

function EncounterStats() {
  return (
    <div className="row">
      <div className="col-lg-5">
        <div className="card">
          <div className="card-header">Your Encounter Statistics</div>
          <div className="card-body">
            <table className="table">
              <tbody>
                <tr>
                  <td colSpan="2" align="center">
                    Total bullets shot:
                  </td>
                  <td align="center">{MOCK_USER.encounter_bulletshot}</td>
                </tr>
                <tr>
                  <td colSpan="2" align="center">
                    Total Encounters:
                  </td>
                  <td align="center">{MOCK_STATS.totalEncounters}</td>
                </tr>
                <tr>
                  <td colSpan="2" align="center">
                    Total Mobsters defeated:
                  </td>
                  <td align="center">{MOCK_STATS.totalMobsDefeated}</td>
                </tr>
                <tr>
                  <td colSpan="2" align="center">
                    Total Failed:
                  </td>
                  <td align="center">{MOCK_STATS.totalFailed}</td>
                </tr>
                <tr>
                  <td colSpan="2" align="center">
                    Total Common defeated:
                  </td>
                  <td align="center">{MOCK_STATS.totalCommon}</td>
                </tr>
                <tr>
                  <td colSpan="2" align="center">
                    Total Rare defeated:
                  </td>
                  <td align="center">{MOCK_STATS.totalRare}</td>
                </tr>
                <tr>
                  <td colSpan="2" align="center">
                    Total Boss defeated:
                  </td>
                  <td align="center">{MOCK_STATS.totalBoss}</td>
                </tr>
                <tr>
                  <td colSpan="2" align="center">
                    Total The Don defeated:
                  </td>
                  <td align="center">{MOCK_STATS.totalDon}</td>
                </tr>
                <tr>
                  <td colSpan="2" align="center">
                    Total Crooked Cops defeated:
                  </td>
                  <td align="center">{MOCK_STATS.totalCop}</td>
                </tr>
                <tr>
                  <td colSpan="2" align="center">
                    Total Special defeated:
                  </td>
                  <td align="center">{MOCK_STATS.totalSpecial}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="col-lg-1"></div>
      <div className="col-lg-5">
        <div className="card">
          <div className="card-header">Total Rewards</div>
          <div className="card-body">
            <table className="table">
              <tbody>
                <tr>
                  <td align="center">Money</td>
                  <td align="center">£{MOCK_USER.en_money.toLocaleString()}</td>
                </tr>
                <tr>
                  <td align="center">Rankpoints</td>
                  <td align="center">
                    {MOCK_USER.en_rankpoints.toLocaleString()}
                  </td>
                </tr>
                <tr>
                  <td align="center">Points</td>
                  <td align="center">{MOCK_USER.en_points}</td>
                </tr>
                <tr>
                  <td align="center">Bullets</td>
                  <td align="center">{MOCK_USER.en_bullets}</td>
                </tr>
                <tr>
                  <td align="center">Kevlar</td>
                  <td align="center">{MOCK_USER.en_kevlar}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="card mt-3">
          <div className="card-header">Quick Encounter</div>
          <div className="card-body text-center">
            Fancy another quick encounter? Why not buy one? (£50,000)
            <button className="btn btn-primary ms-2">Spawn</button>
          </div>
        </div>
      </div>
      <div className="col-lg-1"></div>
    </div>
  );
}

function EncounterInvites({ setAlertData }) {
  const [invites, setInvites] = useState([]);
  const { data: invitesData, isLoading } = useGetEncounterInvitesQuery();
  const [acceptInvite] = useAcceptInviteMutation();
  const [declineInvite] = useDeclineInviteMutation();

  useEffect(() => {
    if (invitesData) {
      setInvites(invitesData);
    }
  }, [invitesData]);

  const handleAcceptInvite = async inviteUuid => {
    try {
      const result = await acceptInvite({ inviteUuid }).unwrap();
      setAlertData({ message: result.message, type: result.type });
      if (result.type === 'success') {
        setInvites(invites.filter(invite => invite.uuid !== inviteUuid));
      }
    } catch (error) {
      setAlertData({
        message: error.data?.message || 'Failed to accept invite',
        type: 'error',
      });
    }
  };

  const handleDeclineInvite = async inviteUuid => {
    try {
      const result = await declineInvite({ inviteUuid }).unwrap();
      setAlertData({ message: result.message, type: result.type });
      if (result.type === 'success') {
        setInvites(invites.filter(invite => invite.uuid !== inviteUuid));
      }
    } catch (error) {
      setAlertData({
        message: error.data?.message || 'Failed to decline invite',
        type: 'error',
      });
    }
  };

  if (isLoading) return <div>Loading invites...</div>;

  return (
    <div className="row">
      <div className="col-lg-2"></div>
      <div className="col-lg-8">
        <div className="card">
          <div className="card-header">Encounter Invites</div>
          <div className="card-body">
            <table className="table">
              <thead>
                <tr>
                  <td>Sent by</td>
                  <td>Encounter Type</td>
                  <td>Action</td>
                </tr>
              </thead>
              <tbody>
                {invites.map(invite => (
                  <tr key={invite.uuid}>
                    <td>{invite.encounters_active?.playerOne?.username}</td>
                    <td>{invite.encounters_active?.encounters_type?.name}</td>
                    <td>
                      <button
                        className="btn btn-primary me-2"
                        onClick={() => handleAcceptInvite(invite.uuid)}
                      >
                        Accept
                      </button>
                      <button
                        className="btn btn-danger"
                        onClick={() => handleDeclineInvite(invite.uuid)}
                      >
                        Decline
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="col-lg-2"></div>
    </div>
  );
}

function EncounterFight({ encounterData, setAlertData }) {
  const [selectedBandage, setSelectedBandage] = useState('');
  const [inviteUsername, setInviteUsername] = useState('');
  const [encounterItem] = useEncounterItemMutation();
  const [confirmAction, setConfirmAction] = useState('select');
  const [shoot] = useShootMutation();
  const [run] = useRunMutation();
  const [sendInvite] = useSendInviteMutation();
  const { data: itemsData } = useGetHeldEncounterItemsQuery();
  const [heldItems, setHeldItems] = useState([]);

  useEffect(() => {
    if (itemsData) {
      setHeldItems(itemsData);
    }
  }, [itemsData]);

  const hasStarted =
    encounterData.healthRemaining < encounterData.encounters_type.health ||
    encounterData.oneHealth < 100 ||
    (encounterData.twoUuid && encounterData.twoHealth < 100) ||
    (encounterData.threeUuid && encounterData.threeHealth < 100);

  const healthPercentage = Math.round(
    (encounterData.healthRemaining / encounterData.encounters_type.health) * 100
  );
  const remainingHealth = Math.max(0, healthPercentage);

  const handleShoot = async () => {
    try {
      const result = await shoot().unwrap();
      setAlertData({ message: result.message, type: result.type });
    } catch (error) {
      setAlertData({
        message: error.data?.message || 'Failed to shoot',
        type: 'error',
      });
    }
  };

  // At component level

  // Then in your handler
  const handleUseEncounterItem = async () => {
    if (selectedBandage) {
      try {
        const result = await encounterItem(selectedBandage).unwrap();
        setAlertData({ message: result.message, type: result.type });
      } catch (error) {
        setAlertData({
          message: error.data?.message || 'Failed to use item',
          type: 'error',
        });
      }
    }
  };

  const handleRun = async () => {
    if (confirmAction === 'Yes!') {
      try {
        const result = await run().unwrap();
        setAlertData({ message: result.message, type: result.type });
      } catch (error) {
        setAlertData({
          message: error.data?.message || 'Failed to run',
          type: 'error',
        });
      }
    }
  };

  const handleInvite = async () => {
    if (inviteUsername) {
      try {
        const result = await sendInvite({ username: inviteUsername }).unwrap();
        setAlertData({ message: result.message, type: result.type });
        if (result.type === 'success') {
          setInviteUsername('');
        }
      } catch (error) {
        setAlertData({
          message: error.data?.message || 'Failed to send invite',
          type: 'error',
        });
      }
    }
  };

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="card">
          <div className="card-header">
            <div className="row text-center">
              {encounterData.oneUuid && (
                <div className="col">{encounterData.playerOne.username}</div>
              )}
              {encounterData.twoUuid && (
                <div className="col">{encounterData.playerTwo.username}</div>
              )}
              {encounterData.threeUuid && (
                <div className="col">{encounterData.playerThree.username}</div>
              )}
            </div>
          </div>

          <div className="card-body">
            <table className="table">
              <tbody>
                <tr>
                  {encounterData.oneUuid && (
                    <td
                      style={{
                        padding: 0,
                        position: 'relative',
                        height: '30px',
                        backgroundColor: '#dc3545',
                        overflow: 'hidden',
                      }}
                    >
                      {encounterData.oneHealth <= 0 ? (
                        <div
                          style={{
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            color: 'white',
                            backgroundColor: '#dc3545',
                            textShadow: '1px 1px 1px rgba(0,0,0,0.5)',
                          }}
                        >
                          Ran away!
                        </div>
                      ) : (
                        <div
                          style={{
                            width: `${encounterData.oneHealth}%`,
                            backgroundColor: '#28a745',
                            height: '100%',
                            transition: 'width 0.3s ease',
                            position: 'relative',
                            float: 'left',
                          }}
                        >
                          <span
                            style={{
                              position: 'absolute',
                              width: '100%',
                              textAlign: 'center',
                              color: 'white',
                              textShadow: '1px 1px 1px rgba(0,0,0,0.5)',
                              lineHeight: '30px',
                            }}
                          >
                            {encounterData.oneHealth}%
                          </span>
                        </div>
                      )}
                    </td>
                  )}
                  {encounterData.twoUuid && (
                    <td
                      style={{
                        padding: 0,
                        position: 'relative',
                        height: '30px',
                        backgroundColor: '#dc3545',
                        overflow: 'hidden',
                      }}
                    >
                      {encounterData.twoHealth <= 0 ? (
                        <div
                          style={{
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            color: 'white',
                            backgroundColor: '#dc3545',
                            textShadow: '1px 1px 1px rgba(0,0,0,0.5)',
                          }}
                        >
                          Ran away!
                        </div>
                      ) : (
                        <div
                          style={{
                            width: `${encounterData.twoHealth}%`,
                            backgroundColor: '#28a745',
                            height: '100%',
                            transition: 'width 0.3s ease',
                            position: 'relative',
                            float: 'left',
                          }}
                        >
                          <span
                            style={{
                              position: 'absolute',
                              width: '100%',
                              textAlign: 'center',
                              color: 'white',
                              textShadow: '1px 1px 1px rgba(0,0,0,0.5)',
                              lineHeight: '30px',
                            }}
                          >
                            {encounterData.twoHealth}%
                          </span>
                        </div>
                      )}
                    </td>
                  )}
                  {encounterData.threeUuid && (
                    <td
                      style={{
                        padding: 0,
                        position: 'relative',
                        height: '30px',
                        backgroundColor: '#dc3545',
                        overflow: 'hidden',
                      }}
                    >
                      {encounterData.threeHealth <= 0 ? (
                        <div
                          style={{
                            position: 'absolute',
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            color: 'white',
                            backgroundColor: '#dc3545',
                            textShadow: '1px 1px 1px rgba(0,0,0,0.5)',
                          }}
                        >
                          Ran away!
                        </div>
                      ) : (
                        <div
                          style={{
                            width: `${encounterData.threeHealth}%`,
                            backgroundColor: '#28a745',
                            height: '100%',
                            transition: 'width 0.3s ease',
                            position: 'relative',
                            float: 'left',
                          }}
                        >
                          <span
                            style={{
                              position: 'absolute',
                              width: '100%',
                              textAlign: 'center',
                              color: 'white',
                              textShadow: '1px 1px 1px rgba(0,0,0,0.5)',
                              lineHeight: '30px',
                            }}
                          >
                            {encounterData.threeHealth}%
                          </span>
                        </div>
                      )}
                    </td>
                  )}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="col-lg-3"></div>
      <div className="col-lg-6 mt-4">
        <div className="card">
          <div className="card-header">
            {encounterData.encounters_type.name} Encounter
          </div>

          <div
            className="card-body d-flex flex-column justify-content-between"
            style={{
              backgroundImage: `url(/assets/img/encounter/${encounterData.encounters_type.image})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              minHeight: '400px',
            }}
          >
            <div>
              <div className="mobster-health text-center">
                {encounterData.healthRemaining} /{' '}
                {encounterData.encounters_type.health} HP
                <div className="progress">
                  <div
                    className="progress-bar bg-success"
                    style={{ width: `${remainingHealth}%` }}
                  >
                    {remainingHealth}%
                  </div>
                </div>
              </div>

              {hasStarted && (
                <div className="text-end mt-3">
                  <select
                    value={selectedBandage}
                    onChange={e => setSelectedBandage(e.target.value)}
                    className="form-select d-inline-block w-auto me-2"
                  >
                    <option value="">Select Bandage</option>
                    {heldItems.map(item => (
                      <option key={item.itemUuid} value={item.itemUuid}>
                        {item.encounters_item.name} +
                        {item.encounters_item.power}%
                      </option>
                    ))}
                  </select>
                  <button
                    className="btn btn-primary"
                    onClick={handleUseEncounterItem}
                  >
                    Use Item
                  </button>
                </div>
              )}

              <div className="text-end mt-3">
                <button className="btn btn-primary" onClick={handleShoot}>
                  Shoot 5 Bullets!
                </button>
              </div>
            </div>

            <div className="mt-auto d-flex justify-content-between">
              <div>
                {!hasStarted &&
                  (!encounterData.twoUuid || !encounterData.threeUuid) && (
                    <>
                      <input
                        type="text"
                        value={inviteUsername}
                        onChange={e => setInviteUsername(e.target.value)}
                        placeholder="Username to invite"
                        className="form-control d-inline-block w-auto me-2"
                      />
                      <button
                        className="btn btn-primary"
                        onClick={handleInvite}
                      >
                        Invite!
                      </button>
                    </>
                  )}
              </div>
              <div>
                <select
                  value={confirmAction}
                  onChange={e => setConfirmAction(e.target.value)}
                  className="form-select d-inline-block w-auto me-2"
                >
                  <option value="select">Are you sure?</option>
                  <option value="Yes!">Yes!</option>
                </select>
                <button className="btn btn-primary" onClick={handleRun}>
                  Run Away
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-3"></div>
    </div>
  );
}

function Encounters() {
  const { data: encounterStatus, isLoading } = useGetEncounterStatusQuery();
  const [alertData, setAlertData] = useState({ message: '', type: '' });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="container-fluid">
      <Response message={alertData.message} type={alertData.type} />
      {encounterStatus?.inEncounter ? (
        <EncounterFight
          encounterData={encounterStatus.encounterData}
          setAlertData={setAlertData}
        />
      ) : (
        <>
          <EncounterInvites setAlertData={setAlertData} />
          <EncounterStats />
        </>
      )}
    </div>
  );
}

export default Encounters;
