import React from 'react';
import { AppSettings } from './config/app-settings.js';
import { useAppConfig } from './config/appConfig.js';
import { GlobalStateProvider } from './auth/globalState.js';
import Header from './components/header/header.jsx';
import Sidebar from './components/sidebar/sidebar.jsx';
import SidebarRight from './components/sidebar-right/sidebar-right.jsx';
import TopMenu from './components/top-menu/top-menu.jsx';
import Content from './components/content/content.jsx';
import Timers from './components/timers/timers.jsx';
import ChatArea from './components/chatarea/chatArea.jsx';

function App() {
  const appConfig = useAppConfig();
  return (
    <GlobalStateProvider>
      <AppSettings.Provider value={appConfig}>
        <div
          className={
            'app ' +
            (appConfig.appGradientEnabled ? 'app-gradient-enabled ' : '') +
            (appConfig.appHeaderNone ? 'app-without-header ' : '') +
            (appConfig.appHeaderFixed && !appConfig.appHeaderNone
              ? 'app-header-fixed '
              : '') +
            (appConfig.appSidebarFixed ? 'app-sidebar-fixed ' : '') +
            (appConfig.appSidebarNone ? 'app-without-sidebar ' : '') +
            (appConfig.appSidebarEnd ? 'app-with-end-sidebar ' : '') +
            (appConfig.appSidebarWide ? 'app-with-wide-sidebar ' : '') +
            (appConfig.appSidebarMinify ? 'app-sidebar-minified ' : '') +
            (appConfig.appSidebarMobileToggled
              ? 'app-sidebar-mobile-toggled '
              : '') +
            (appConfig.appTopMenu ? 'app-with-top-menu ' : '') +
            (appConfig.appContentFullHeight ? 'app-content-full-height ' : '') +
            (appConfig.appSidebarTwo ? 'app-with-two-sidebar ' : '') +
            (appConfig.appSidebarEndToggled ? 'app-sidebar-end-toggled ' : '') +
            (appConfig.appSidebarEndMobileToggled
              ? 'app-sidebar-end-mobile-toggled '
              : '') +
            (appConfig.hasScroll ? 'has-scroll ' : '')
          }
        >
          {!appConfig.appHeaderNone && <Header />}
          <div
            onClick={() => appConfig.isChatOpen && appConfig.toggleChatArea()}
          >
            {!appConfig.appSidebarNone && <Sidebar />}
            {appConfig.appSidebarTwo && <SidebarRight />}
            {appConfig.appTopMenu && <TopMenu />}
            {!appConfig.appContentNone && (
              <>
                <Timers />
                <Content />
              </>
            )}
          </div>
          <ChatArea isOpen={appConfig.isChatOpen} />
        </div>
      </AppSettings.Provider>
    </GlobalStateProvider>
  );
}

export default App;
