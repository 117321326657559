import React, { useState } from 'react';
import {
  useGetLocationsQuery,
  useGetTravelPageDataQuery,
  useTravelPostMutation,
} from '../../api/general';
import Response from '../../shared-components/response';

const Travel = () => {
  const {
    data: locations,
    isLoading: locationsLoading,
    error: locationsError,
  } = useGetLocationsQuery();
  const {
    data: travelPageData,
    isLoading: travelPageDataLoading,
    error: travelPageDataError,
  } = useGetTravelPageDataQuery();
  const [alertData, setAlertData] = useState({ message: '', type: '' });
  const [travelPost] = useTravelPostMutation();

  if (locationsLoading || travelPageDataLoading) {
    return <div>Loading...</div>;
  }

  if (locationsError || travelPageDataError) {
    return (
      <div>
        Error: {locationsError?.message || travelPageDataError?.message}
      </div>
    );
  }

  const { carName, waitTimeMinutes } = travelPageData || {};

  const handleImageDoubleClick = async location => {
    try {
      const response = await travelPost(location).unwrap();
      setAlertData({ message: response.message, type: response.type });
    } catch (error) {
      setAlertData({
        message: 'An error occurred while traveling',
        type: 'error',
      });
    }
  };

  return (
    <div className="row">
      <div className="col-lg-4"></div>
      <div className="col-lg-4">
        <Response message={alertData.message} type={alertData.type} />

        <div className="card">
          <div className="card-body">
            <h3 className="card-title text-center">Drive</h3>
            <h5 className="card-subtitle mb-2 text-muted text-center">
              Car: {carName} ({waitTimeMinutes} minutes)
            </h5>
            <div className="d-flex flex-column align-items-center">
              {locations.map(location => (
                <div key={location.uuid} className="mb-3">
                  <img
                    src={`/assets/img/travel/${location.city.toLowerCase()}.png`}
                    alt={location.city}
                    onDoubleClick={() => handleImageDoubleClick(location)}
                    className="clickable-image"
                    style={{ maxWidth: '100%', height: 'auto' }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-4"></div>
    </div>
  );
};

export default Travel;
