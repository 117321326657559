import React, { useState, useEffect } from 'react';
import {
  useGetBlackMarketItemListingsQuery,
  useAddBlackMarketItemListingMutation,
  useGetBlackMarketPropertyListingsQuery,
  useAddBlackMarketPropertyListingMutation,
  useGetBlackMarketInsuranceListingsQuery,
  useAddBlackMarketInsuranceListingMutation,
  useGetBlackMarketPointListingsQuery,
  useAddBlackMarketPointListingMutation,
  useGetBlackMarketLockpickListingsQuery,
  useAddBlackMarketLockpickListingMutation,
  useGetBlackMarketUserItemsQuery,
  useGetBlackMarketUserPropertiesQuery,
  useGetBlackMarketUserInsuranceQuery,
  useBuyBlackMarketListingMutation,
} from '../../api/marketplace';

import Response from '../../shared-components/response';

const Items = ({ setAlertData }) => {
  const [selectedItem, setSelectedItem] = useState('');
  const [price, setPrice] = useState('');
  const { data: listings, refetch } = useGetBlackMarketItemListingsQuery();
  const { data: userItems } = useGetBlackMarketUserItemsQuery();
  const [addListing] = useAddBlackMarketItemListingMutation();
  const [buyListing] = useBuyBlackMarketListingMutation();

  useEffect(() => {
    refetch();
  }, [refetch]);

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      await addListing({
        itemUuid: selectedItem,
        price: Number(price),
      }).unwrap();
      setAlertData({ message: 'Item listed successfully', type: 'success' });
      setSelectedItem('');
      setPrice('');
      refetch();
    } catch (error) {
      setAlertData({ message: 'Failed to list item', type: 'danger' });
    }
  };

  return (
    <>
      <div className="card mb-3">
        <div className="card-body">
          <h3 className="card-title">Item Listings</h3>
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>Item</th>
                  <th>Price</th>
                  <th>Seller</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {listings?.map(item => (
                  <tr key={item.id}>
                    <td>{item.title}</td>
                    <td>${item.price}</td>
                    <td>{item.seller}</td>
                    <td>
                      <button
                        className="btn btn-primary btn-sm"
                        onClick={() => {
                          buyListing({ listingId: item.uuid })
                            .unwrap()
                            .then(() => {
                              setAlertData({
                                message: 'Purchase successful',
                                type: 'success',
                              });
                              refetch();
                            })
                            .catch(error => {
                              setAlertData({
                                message:
                                  error.data?.message || 'Purchase failed',
                                type: 'danger',
                              });
                            });
                        }}
                      >
                        Buy
                      </button>{' '}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-body">
          <h3 className="card-title">List an Item</h3>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <select
                value={selectedItem}
                onChange={e => setSelectedItem(e.target.value)}
                className="form-select"
              >
                <option value="">Select Item</option>
                {userItems?.map(item => (
                  <option key={item.uuid} value={item.uuid}>
                    {item.title}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-3">
              <input
                type="number"
                value={price}
                onChange={e => setPrice(e.target.value)}
                className="form-control"
                placeholder="Price"
                min="10000"
              />
            </div>
            <button type="submit" className="btn btn-primary">
              List Item
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

const Properties = ({ setAlertData }) => {
  const [selectedProperty, setSelectedProperty] = useState('');
  const [price, setPrice] = useState('');
  const { data: listings, refetch } = useGetBlackMarketPropertyListingsQuery();
  const { data: userProperties } = useGetBlackMarketUserPropertiesQuery();
  const [addListing] = useAddBlackMarketPropertyListingMutation();
  const [buyListing] = useBuyBlackMarketListingMutation();

  useEffect(() => {
    refetch();
  }, [refetch]);

  const handleSubmit = async e => {
    e.preventDefault();
    // Split the value into type and uuid
    const [propertyType, propertyUuid] = selectedProperty.split('|');
    try {
      await addListing({
        propertyUuid: propertyUuid,
        propertyType: propertyType,
        price: Number(price),
      }).unwrap();
      setAlertData({
        message: 'Property listed successfully',
        type: 'success',
      });
      setSelectedProperty('');
      setPrice('');
      refetch();
    } catch (error) {
      setAlertData({ message: 'Failed to list property', type: 'danger' });
    }
  };

  return (
    <>
      <div className="card mb-3">
        <div className="card-body">
          <h3 className="card-title">Property Listings</h3>
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>Property</th>
                  <th>Location</th>
                  <th>Price</th>
                  <th>Seller</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {listings?.map(property => (
                  <tr key={property.uuid}>
                    <td>{property.propertyType}</td>
                    <td>{property.location}</td>
                    <td>${property.price.toLocaleString()}</td>
                    <td>{property.seller}</td>
                    <td>
                      <button
                        className="btn btn-primary btn-sm"
                        onClick={() => {
                          buyListing({ listingId: property.uuid })
                            .unwrap()
                            .then(() => {
                              setAlertData({
                                message: 'Purchase successful',
                                type: 'success',
                              });
                              refetch();
                            })
                            .catch(error => {
                              setAlertData({
                                message:
                                  error.data?.message || 'Purchase failed',
                                type: 'danger',
                              });
                            });
                        }}
                      >
                        Buy
                      </button>{' '}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-body">
          <h3 className="card-title">List a Property</h3>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <select
                value={selectedProperty}
                onChange={e => setSelectedProperty(e.target.value)}
                className="form-select"
              >
                <option value="">Select Property</option>
                {userProperties &&
                  Object.entries(
                    userProperties.reduce((groups, property) => {
                      if (!groups[property.city]) {
                        groups[property.city] = [];
                      }
                      const formattedType = property.type
                        .replace('bulletFactory', 'Bullet Factory')
                        .replace('kevlarFactory', 'Kevlar Factory')
                        .replace(/^[a-z]/, letter => letter.toUpperCase());
                      groups[property.city].push({
                        ...property,
                        formattedType,
                      });
                      return groups;
                    }, {})
                  ).map(([city, properties]) => (
                    <optgroup key={city} label={city}>
                      {properties.map(property => (
                        <option
                          key={property.uuid}
                          value={`${property.type}|${property.uuid}`}
                        >
                          {property.formattedType}
                        </option>
                      ))}
                    </optgroup>
                  ))}
              </select>
            </div>
            <div className="mb-3">
              <input
                type="number"
                value={price}
                onChange={e => setPrice(e.target.value)}
                className="form-control"
                placeholder="Price"
                min="10000"
              />
            </div>
            <button type="submit" className="btn btn-primary">
              List Property
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

const Insurance = ({ setAlertData }) => {
  const [selectedInsurance, setSelectedInsurance] = useState('');

  const [insuranceType, setInsuranceType] = useState('');
  const [price, setPrice] = useState('');
  const { data: listings, refetch } = useGetBlackMarketInsuranceListingsQuery();
  const { data: userPolicies } = useGetBlackMarketUserInsuranceQuery();

  const [addListing] = useAddBlackMarketInsuranceListingMutation();
  const [buyListing] = useBuyBlackMarketListingMutation();

  useEffect(() => {
    refetch();
  }, [refetch]);

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      await addListing({
        insuranceUuid: selectedInsurance,
        price: Number(price),
      }).unwrap();
      setAlertData({
        message: 'Insurance listed successfully',
        type: 'success',
      });
      setSelectedInsurance('');
      setPrice('');
      refetch();
    } catch (error) {
      setAlertData({ message: 'Failed to list insurance', type: 'danger' });
    }
  };

  return (
    <>
      <div className="card mb-3">
        <div className="card-body">
          <h3 className="card-title">Insurance Listings</h3>
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>Policy Type</th>
                  <th>Insured</th>
                  <th>Price</th>
                  <th>Seller</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {listings?.map(insurance => (
                  <tr key={insurance.uuid}>
                    <td>{insurance.policyType}</td>
                    <td>{insurance.insured}</td>
                    <td>£{insurance.price.toLocaleString()}</td>
                    <td>{insurance.seller}</td>
                    <td>
                      <button
                        className="btn btn-primary btn-sm"
                        onClick={() => {
                          buyListing({ listingId: insurance.uuid })
                            .unwrap()
                            .then(() => {
                              setAlertData({
                                message: 'Purchase successful',
                                type: 'success',
                              });
                              refetch();
                            })
                            .catch(error => {
                              setAlertData({
                                message:
                                  error.data?.message || 'Purchase failed',
                                type: 'danger',
                              });
                            });
                        }}
                      >
                        Buy
                      </button>{' '}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-body">
          <h3 className="card-title">List Insurance</h3>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <select
                value={selectedInsurance}
                onChange={e => setSelectedInsurance(e.target.value)}
                className="form-select"
              >
                <option value="">Select Insurance Policy</option>
                {userPolicies?.map(policy => {
                  const policyNames = {
                    1: 'Low Insurance - 15%',
                    2: 'Medium Insurance - 25%',
                    3: 'High Insurance - 35%',
                    4: 'Mega Insurance - 50%',
                  };
                  return (
                    <option key={policy.uuid} value={policy.uuid}>
                      {policy.insured} - {policyNames[policy.policyType]}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="mb-3">
              <input
                type="number"
                value={price}
                onChange={e => setPrice(e.target.value)}
                className="form-control"
                placeholder="Price"
              />
            </div>
            <button type="submit" className="btn btn-primary">
              List Insurance
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

const Points = ({ setAlertData }) => {
  const [amount, setAmount] = useState('');
  const [price, setPrice] = useState('');
  const { data: listings, refetch } = useGetBlackMarketPointListingsQuery();
  const [addListing] = useAddBlackMarketPointListingMutation();
  const [buyListing] = useBuyBlackMarketListingMutation();

  useEffect(() => {
    refetch();
  }, [refetch]);

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      await addListing({
        amount: Number(amount),
        price: Number(price),
      }).unwrap();
      setAlertData({ message: 'Points listed successfully', type: 'success' });
      setAmount('');
      setPrice('');
      refetch();
    } catch (error) {
      setAlertData({ message: 'Failed to list points', type: 'danger' });
    }
  };

  return (
    <>
      <div className="card mb-3">
        <div className="card-body">
          <h3 className="card-title">Points Listings</h3>
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>Amount</th>
                  <th>Price</th>
                  <th>Seller</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {listings?.map(points => (
                  <tr key={points.id}>
                    <td>{points.amount}</td>
                    <td>${points.price}</td>
                    <td>{points.seller}</td>
                    <td>
                      <button
                        className="btn btn-primary btn-sm"
                        onClick={() => {
                          buyListing({ listingId: points.uuid })
                            .unwrap()
                            .then(() => {
                              setAlertData({
                                message: 'Purchase successful',
                                type: 'success',
                              });
                              refetch();
                            })
                            .catch(error => {
                              setAlertData({
                                message:
                                  error.data?.message || 'Purchase failed',
                                type: 'danger',
                              });
                            });
                        }}
                      >
                        Buy
                      </button>{' '}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="card">
        <div className="card-body">
          <h3 className="card-title">List Points</h3>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <input
                type="number"
                value={amount}
                onChange={e => setAmount(e.target.value)}
                className="form-control"
                placeholder="Amount of Points"
              />
            </div>
            <div className="mb-3">
              <input
                type="number"
                value={price}
                onChange={e => setPrice(e.target.value)}
                className="form-control"
                placeholder="Price"
              />
            </div>
            <button type="submit" className="btn btn-primary">
              List Points
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

const Lockpicks = ({ setAlertData }) => {
  const [amount, setAmount] = useState('');
  const [price, setPrice] = useState('');
  const { data: listings, refetch } = useGetBlackMarketLockpickListingsQuery();
  const [addListing] = useAddBlackMarketLockpickListingMutation();
  const [buyListing] = useBuyBlackMarketListingMutation();

  useEffect(() => {
    refetch();
  }, [refetch]);

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      await addListing({
        amount: Number(amount),
        price: Number(price),
      }).unwrap();
      setAlertData({
        message: 'Lockpicks listed successfully',
        type: 'success',
      });
      setAmount('');
      setPrice('');
      refetch();
    } catch (error) {
      setAlertData({ message: 'Failed to list lockpicks', type: 'danger' });
    }
  };

  return (
    <>
      <div className="card mb-3">
        <div className="card-body">
          <h3 className="card-title">Lockpick Listings</h3>
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>Quantity</th>
                  <th>Total Price</th>
                  <th>Seller</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {listings?.map(lockpick => (
                  <tr key={lockpick.id}>
                    <td>{lockpick.amount}</td>
                    <td>${lockpick.price}</td>
                    <td>{lockpick.seller}</td>
                    <td>
                      <button
                        className="btn btn-primary btn-sm"
                        onClick={() => {
                          buyListing({ listingId: lockpick.uuid })
                            .unwrap()
                            .then(() => {
                              setAlertData({
                                message: 'Purchase successful',
                                type: 'success',
                              });
                              refetch();
                            })
                            .catch(error => {
                              setAlertData({
                                message:
                                  error.data?.message || 'Purchase failed',
                                type: 'danger',
                              });
                            });
                        }}
                      >
                        Buy
                      </button>{' '}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <h3 className="card-title">List Lockpicks</h3>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <input
                type="number"
                value={amount}
                onChange={e => setAmount(e.target.value)}
                className="form-control"
                placeholder="Amount"
              />
            </div>
            <div className="mb-3">
              <input
                type="number"
                value={price}
                onChange={e => setPrice(e.target.value)}
                className="form-control"
                placeholder="Price"
              />
            </div>
            <button type="submit" className="btn btn-primary">
              List Lockpicks
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

const BlackMarket = () => {
  const [activeTab, setActiveTab] = useState('items');
  const [alertData, setAlertData] = useState({ message: '', type: '' });

  const handleTabClick = tabName => {
    setActiveTab(tabName);
    setAlertData({ message: '', type: '' });
  };

  return (
    <div>
      <Response message={alertData.message} type={alertData.type} />

      <ul className="nav nav-tabs">
        <li className="nav-item">
          <a
            href="#items-tab"
            onClick={() => handleTabClick('items')}
            className={`nav-link ${activeTab === 'items' ? 'active' : ''}`}
          >
            <span className="d-sm-none">Items</span>
            <span className="d-sm-block d-none">Items</span>
          </a>
        </li>
        <li className="nav-item">
          <a
            href="#properties-tab"
            onClick={() => handleTabClick('properties')}
            className={`nav-link ${activeTab === 'properties' ? 'active' : ''}`}
          >
            <span className="d-sm-none">Properties</span>
            <span className="d-sm-block d-none">Properties</span>
          </a>
        </li>
        <li className="nav-item">
          <a
            href="#insurance-tab"
            onClick={() => handleTabClick('insurance')}
            className={`nav-link ${activeTab === 'insurance' ? 'active' : ''}`}
          >
            <span className="d-sm-none">Insurance</span>
            <span className="d-sm-block d-none">Insurance</span>
          </a>
        </li>
        <li className="nav-item">
          <a
            href="#points-tab"
            onClick={() => handleTabClick('points')}
            className={`nav-link ${activeTab === 'points' ? 'active' : ''}`}
          >
            <span className="d-sm-none">Points</span>
            <span className="d-sm-block d-none">Points</span>
          </a>
        </li>
        <li className="nav-item">
          <a
            href="#lockpicks-tab"
            onClick={() => handleTabClick('lockpicks')}
            className={`nav-link ${activeTab === 'lockpicks' ? 'active' : ''}`}
          >
            <span className="d-sm-none">Lockpicks</span>
            <span className="d-sm-block d-none">Lockpicks</span>
          </a>
        </li>
      </ul>
      <div className="tab-content panel rounded-0 p-3 m-0">
        <div
          className={`tab-pane fade ${activeTab === 'items' ? 'active show' : ''}`}
          id="items-tab"
        >
          <Items setAlertData={setAlertData} />
        </div>
        <div
          className={`tab-pane fade ${activeTab === 'properties' ? 'active show' : ''}`}
          id="properties-tab"
        >
          <Properties setAlertData={setAlertData} />
        </div>
        <div
          className={`tab-pane fade ${activeTab === 'insurance' ? 'active show' : ''}`}
          id="insurance-tab"
        >
          <Insurance setAlertData={setAlertData} />
        </div>
        <div
          className={`tab-pane fade ${activeTab === 'points' ? 'active show' : ''}`}
          id="points-tab"
        >
          <Points setAlertData={setAlertData} />
        </div>
        <div
          className={`tab-pane fade ${activeTab === 'lockpicks' ? 'active show' : ''}`}
          id="lockpicks-tab"
        >
          <Lockpicks setAlertData={setAlertData} />
        </div>
      </div>
    </div>
  );
};

export default BlackMarket;
