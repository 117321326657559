// PropertyPanel.jsx
import React, { useState } from 'react';
import {
  useGetPropertyDetailsQuery,
  useUpdatePropertyMutation,
} from '../../api/properties';
import Response from '../../shared-components/response'; // Assuming this is the path

const PropertyPanel = ({ property }) => {
  const { data: propertyDetails, isLoading } =
    useGetPropertyDetailsQuery(property);
  const [updateProperty] = useUpdatePropertyMutation();
  const [maxBetOrPrice, setMaxBetOrPrice] = useState('');
  const [transferTo, setTransferTo] = useState('');
  const [alertData, setAlertData] = useState({ message: '', type: '' });

  const isFactory = propertyDetails?.name.toLowerCase().includes('factory');

  const handleSetMaxBetOrPrice = async () => {
    try {
      const response = await updateProperty({
        propertyId: property,
        [isFactory ? 'price' : 'maxBet']: maxBetOrPrice,
      }).unwrap();
      setAlertData({ message: response.message, type: response.type });
    } catch (error) {
      setAlertData({ message: error.message, type: error.type });
    }
  };

  const handleTransferProperty = async () => {
    try {
      const response = await updateProperty({
        propertyId: property,
        newOwner: transferTo,
      }).unwrap();
      setAlertData({ message: response.message, type: response.type });
    } catch (error) {
      setAlertData({ message: error.message, type: error.type });
    }
  };

  const handleDropProperty = async () => {
    try {
      const response = await updateProperty({
        propertyId: property,
        drop: true,
      }).unwrap();
      setAlertData({ message: response.message, type: response.type });
    } catch (error) {
      setAlertData({ message: error.message, type: error.type });
    }
  };

  if (isLoading) return <div>Loading...</div>;

  return (
    <div className="property-panel card col-md-6">
      <Response message={alertData.message} type={alertData.type} />{' '}
      {/* Response component */}
      <div className="card-header">
        <h2>Your {propertyDetails.name}</h2>
      </div>
      <div className="card-body">
        <div className="form-group">
          <label>Total Profit:</label> ${propertyDetails.totalProfit || 0}
        </div>{' '}
        <hr />
        <div className="form-group">
          <label>{isFactory ? 'Set Price:' : 'Set Max Bet:'}</label>
          <input
            type="number"
            className="form-control"
            value={maxBetOrPrice}
            onChange={e => setMaxBetOrPrice(e.target.value)}
            placeholder={
              isFactory
                ? `Current price is $${propertyDetails.price || 0}`
                : `Current max bet is $${propertyDetails.maxBet || 0}`
            }
          />
          <button
            className="btn btn-primary mt-2"
            onClick={handleSetMaxBetOrPrice}
          >
            {isFactory ? 'Set Price' : 'Set Max Bet'}
          </button>
        </div>
        <hr />
        <div className="form-group mt-3">
          <label>Send Property To:</label>
          <input
            type="text"
            className="form-control"
            value={transferTo}
            onChange={e => setTransferTo(e.target.value)}
          />
          <button
            className="btn btn-warning mt-2"
            onClick={handleTransferProperty}
          >
            Transfer Property
          </button>
        </div>
        <hr />
        <div className="mt-4">
          <button className="btn btn-danger" onClick={handleDropProperty}>
            Drop Property
          </button>
        </div>
      </div>
    </div>
  );
};

export default PropertyPanel;
