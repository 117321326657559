import React, { useState } from 'react';
import { useCommitChaseMutation } from '../../api/crime';
import Response from '../../shared-components/response';
import { useGlobalState } from '../../auth/globalState';

const Chase = () => {
  const [commitChase] = useCommitChaseMutation();
  const [alertData, setAlertData] = useState({ message: '', type: '' });
  const { updateTimer } = useGlobalState();

  const handleChaseAction = async action => {
    try {
      const response = await commitChase({ choice: action }).unwrap();
      setAlertData({ message: response.message, type: response.type });
      if (response.time) {
        updateTimer('chase', response.time);
      }
    } catch (error) {
      setAlertData({ message: 'An error occurred', type: 'error' });
    }
  };
  return (
    <div className="row">
      <div className="col-lg-3"></div>
      <div className="col-lg-6">
        <Response message={alertData.message} type={alertData.type} />

        <div className="card">
          <div className="card-body">
            <img src="/assets/img/chase.webp" alt="" className="card-img-top" />

            <h2 className="card-title text-center mb-4">Chase</h2>
            <div className="d-flex justify-content-between mb-4">
              <button
                className="btn btn-primary"
                onClick={() => handleChaseAction('left')}
              >
                Turn Left
              </button>
              <button
                className="btn btn-primary"
                onClick={() => handleChaseAction('straight')}
              >
                Straight Ahead
              </button>
              <button
                className="btn btn-primary"
                onClick={() => handleChaseAction('right')}
              >
                Turn Right
              </button>
            </div>
            <div className="d-flex justify-content-center">
              <button
                className="btn btn-primary"
                onClick={() => handleChaseAction('turnaround')}
              >
                Turn Around
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-3"></div>
    </div>
  );
};

export default Chase;
