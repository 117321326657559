import { apiSlice } from '../auth/apiSlice';

export const familyApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getFamilyInfo: builder.query({
      query: () => '/family/info',
      providesTags: ['family_info'],
    }),
    createFamily: builder.mutation({
      query: body => ({
        url: '/family/create',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['family_info'],
    }),
    getFamilyInvites: builder.query({
      query: () => '/family/invites',
      providesTags: ['family_invites'],
    }),
    respondToInvite: builder.mutation({
      query: body => ({
        url: '/family/respond-invite',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['family_invites', 'family_info'],
    }),
    inviteUser: builder.mutation({
      query: body => ({
        url: '/family/invite',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['family_info'],
    }),
    kickUser: builder.mutation({
      query: body => ({
        url: '/family/kick',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['family_info'],
    }),
    leaveFamily: builder.mutation({
      query: () => ({
        url: '/family/leave',
        method: 'POST',
      }),
      invalidatesTags: ['family_info'],
    }),
    getFamilyBusinesses: builder.query({
      query: () => '/family/businesses',
      providesTags: ['family_businesses'],
    }),
    buyFamilyBusiness: builder.mutation({
      query: businessUuid => ({
        url: '/family/buy-business',
        method: 'POST',
        body: { businessUuid },
      }),
      invalidatesTags: ['family_businesses', 'family_info'],
    }),
    sellFamilyBusiness: builder.mutation({
      query: businessUuid => ({
        url: '/family/sell-business',
        method: 'POST',
        body: { businessUuid },
      }),
      invalidatesTags: ['family_businesses', 'family_info'],
    }),

    getFollowers: builder.query({
      query: () => '/family/followerinfo',
      providesTags: ['family_followers'],
    }),
    recruitFollower: builder.mutation({
      query: body => ({
        url: '/family/followers',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['family_followers'],
    }),
    getFamilyDrinks: builder.query({
      query: () => '/family/drinks',
      providesTags: ['family_drinks'],
    }),
    sendDrink: builder.mutation({
      query: body => ({
        url: '/family/send-drink',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['family_drinks'],
    }),
    buildFamilyBuilding: builder.mutation({
      query: buildingName => ({
        url: '/family/build-building',
        method: 'POST',
        body: { buildingName },
      }),
      invalidatesTags: ['family_info', 'family_followers'],
    }),
    sendMassMessage: builder.mutation({
      query: message => ({
        url: '/family/send-mass-message',
        method: 'POST',
        body: { message },
      }),
    }),
  }),
});

export const {
  useGetFamilyInfoQuery,
  useCreateFamilyMutation,
  useGetFamilyInvitesQuery,
  useRespondToInviteMutation,
  useInviteUserMutation,
  useKickUserMutation,
  useLeaveFamilyMutation,
  useGetFamilyBusinessesQuery,
  useBuyFamilyBusinessMutation,
  useSellFamilyBusinessMutation,
  useGetFollowersQuery,
  useRecruitFollowerMutation,
  useGetFamilyDrinksQuery,
  useSendDrinkMutation,
  useBuildFamilyBuildingMutation,
  useSendMassMessageMutation,
} = familyApi;
