import { apiSlice } from '../auth/apiSlice';

export const encountersApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getEncounterStatus: builder.query({
      query: () => 'encounters/status',
      providesTags: ['Encounter'],
    }),

    getEncounterStats: builder.query({
      query: () => 'encounters/stats',
      providesTags: ['EncounterStats'],
    }),

    shoot: builder.mutation({
      query: () => ({
        url: '/encounters/shoot',
        method: 'POST',
      }),
    }),
    run: builder.mutation({
      query: () => ({
        url: 'encounters/run',
        method: 'POST',
      }),
      invalidatesTags: ['Encounter'],
    }),

    spawnEncounter: builder.mutation({
      query: () => ({
        url: 'encounters/spawn',
        method: 'POST',
      }),
      invalidatesTags: ['Encounter', 'EncounterStats'],
    }),
    getEncounterInvites: builder.query({
      query: () => 'encounters/invites',
      providesTags: ['Encounter'],
    }),

    sendInvite: builder.mutation({
      query: username => ({
        url: 'encounters/invite/send',
        method: 'POST',
        body: username,
      }),
      invalidatesTags: ['Encounter'],
    }),

    acceptInvite: builder.mutation({
      query: body => ({
        url: 'encounters/invite/accept',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Encounter'],
    }),

    declineInvite: builder.mutation({
      query: body => ({
        url: 'encounters/invite/decline',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Encounter'],
    }),
    getHeldEncounterItems: builder.query({
      query: () => 'encounters/items/held',
      providesTags: ['Items'],
    }),
    EncounterItem: builder.mutation({
      query: itemUuid => ({
        url: 'encounters/use-item',
        method: 'POST',
        body: { itemUuid },
      }),
      invalidatesTags: ['Encounter', 'Items'],
    }),
  }),
});

export const {
  useGetEncounterStatusQuery,
  useGetEncounterStatsQuery,
  useShootMutation,
  useRunMutation,
  useSpawnEncounterMutation,
  useGetEncounterInvitesQuery,
  useSendInviteMutation,
  useAcceptInviteMutation,
  useDeclineInviteMutation,
  useGetHeldEncounterItemsQuery,
  useEncounterItemMutation,
} = encountersApi;
