import { apiSlice } from '../auth/apiSlice';

export const propertiesApi = apiSlice.injectEndpoints({
  endpoints: builder => ({
    isPropertyOwner: builder.mutation({
      query: body => ({
        url: '/properties/ispropertyowner',
        method: 'POST',
        body,
      }),
    }),
    getPropertyDetails: builder.query({
      query: propertyId => `/properties/one/${propertyId}`,
    }),
    updateProperty: builder.mutation({
      query: body => ({
        url: '/properties/update',
        method: 'POST',
        body,
      }),
    }),
    getAllProperties: builder.query({
      query: () => '/properties/all',
    }),
    collectProperty: builder.mutation({
      query: body => ({
        url: '/properties/collect',
        method: 'POST',
        body,
      }),
    }),
    playRoulette: builder.mutation({
      query: body => ({
        url: '/properties/roulette/play',
        method: 'POST',
        body,
      }),
    }),
    playSlots: builder.mutation({
      query: body => ({
        url: '/properties/slots/play',
        method: 'POST',
        body,
      }),
    }),
    buyFromFactory: builder.mutation({
      query: body => ({
        url: '/properties/buy-from-factory',
        method: 'POST',
        body,
      }),
    }),
    PlayScratchcard: builder.mutation({
      query: body => ({
        url: '/properties/scratchcards/play',
        method: 'POST',
        body,
      }),
    }),
    getLastScratchcards: builder.query({
      query: () => '/properties/scratchcards/last',
    }),
    playDice: builder.mutation({
      query: body => ({
        url: '/properties/dice/play',
        method: 'POST',
        body,
      }),
    }),
    playHeadsTails: builder.mutation({
      query: body => ({
        url: '/properties/heads-or-tails/play',
        method: 'POST',
        body,
      }),
    }),
    getLotteryInfo: builder.query({
      query: () => '/properties/lottery/info',
    }),
    getUserTickets: builder.query({
      query: () => '/properties/lottery/user-tickets',
    }),
    getDrawHistory: builder.query({
      query: () => '/properties/lottery/draw-history',
    }),
    buyTicket: builder.mutation({
      query: numbers => ({
        url: '/properties/lottery/buy-ticket',
        method: 'POST',
        body: { numbers },
      }),
    }),
    // Add these to the existing endpoints object
    playKeno: builder.mutation({
      query: body => ({
        url: '/properties/keno/play',
        method: 'POST',
        body,
      }),
    }),

    getKenoJackpot: builder.query({
      query: () => '/properties/keno/jackpot',
    }),
    buyFromFoundry: builder.mutation({
      query: body => ({
        url: '/kill/foundry/buy',
        method: 'POST',
        body,
      }),
    }),
    getFoundryStock: builder.query({
      query: () => '/kill/foundry/stock',
    }),
  }),
});

export const {
  useIsPropertyOwnerMutation,
  useGetPropertyDetailsQuery,
  useUpdatePropertyMutation,
  useGetAllPropertiesQuery,
  useCollectPropertyMutation,
  usePlayRouletteMutation,
  usePlaySlotsMutation,
  useBuyFromFactoryMutation,
  usePlayScratchcardMutation,
  useGetLastScratchcardsQuery,
  usePlayDiceMutation,
  usePlayHeadsTailsMutation,
  useGetLotteryInfoQuery,
  useGetUserTicketsQuery,
  useGetDrawHistoryQuery,
  useBuyTicketMutation,
  usePlayKenoMutation,
  useGetKenoJackpotQuery,
  useBuyFromFoundryMutation,
  useGetFoundryStockQuery,
} = propertiesApi;
